import React from 'react';
import './QmiCard.scss';
import $ from 'jquery';
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import SectionTitle from './SectionTitle';

const qmi_options = {
    loop: true,
    items: 1,
    nav: true,
    dots: true,
    margin: 0,
    autoplay: true,
}
class QmiCard extends React.Component {
    constructor(props) {

        super(props);

        this.state = {
            visible: 6
        };
        this.loadMore = this.loadMore.bind(this);
    }
    componentDidMount() {
        if (window.screen.width > 1024) {
            this.load_card_height();
        }
    }
    componentDidUpdate() {
        if (window.screen.width > 1024) {
            this.load_card_height();
        }
    }
    load_card_height() {
        var card_content_height = [];

        var qmi_card_div = $(document).find(".qmi-card .card-main-div");
        qmi_card_div.each(function () {
            card_content_height.push($(this).find(".card-hover-content").outerHeight());
        });
        var card_height = $(qmi_card_div[0]).outerHeight();
        var card_content_maxheight = Math.max.apply(Math, card_content_height);
        var image_height = card_height - card_content_maxheight;
        $(".qmi-card .card-main-div").mouseenter(function () {
            var card_hover_content_div = $(this).find('.card-hover-content');
            var style_object = {
                "height": '0px',
                'visibility': 'visible'
            }
            $(this).find('.card-imgs').animate({ height: image_height }, 300);
            card_hover_content_div.css(style_object);
            card_hover_content_div.animate({ height: card_content_maxheight }, 300);
            var card_info_div = $(this).find('.card-info');
            card_info_div.css('display', 'none');

        });
        $(".qmi-card .card-main-div").mouseleave(function () {
            var self = this;
            setTimeout(function () {
                $(self).find('.card-hover-content').css('visibility', 'hidden');
                $(self).find('.card-info').css('visibility', 'visible');
            }, 100);
            var card_info_div = $(this).find('.card-info');
            card_info_div.css('display', 'block');
            card_info_div.css('visibility', 'hidden');
            var card_info_height = card_info_div.innerHeight();
            $(this).find('.card-imgs').animate({ height: card_height - card_info_height }, 300);

        });
    }

    loadMore() {
        document.getElementById("load_more_loader").style.display = "inline-block";
        this.setState((prev) => {
            return { visible: prev.visible + 6 };
        }, () => {
            if (document.getElementById("load_more_loader")) {
                document.getElementById("load_more_loader").style.display = "none";
            }
        })
    }
    render() {
        var Qmi_detail = this.props.Qmi_detail;
        var window_width = window.screen.width;
        var toShow;
        console.log(Qmi_detail, Qmi_detail.length);
        return (
            Qmi_detail.length > 0 ? [
                <div className="qmi-card-section" id="qmi-card-section">
                    <div className="container">
                        <SectionTitle subtext='Available Homes' card_name='qmi' />
                        <div className="qmi-card">
                            {Qmi_detail.slice(0, this.state.visible).map(items => (
                                <div className="card-main-div col-lg-6 col-sm-6">
                                    <div className="card-imgs">
                                        <div className="plan-img">
                                            {items.card_image.length > 0 ? (
                                                items.card_image.length === 1 ? (
                                                    <Link to={items.post_permalink}>
                                                        <div className="overlay">
                                                            <img src={items.card_image[0]} className="image-building-image" alt={"New homes in " + items.card_image_title[0]} title={items.post_title} />
                                                        </div>
                                                    </Link>
                                                ) : (
                                                        <OwlCarousel {...qmi_options} className="owl-theme">
                                                            {items.card_image.map((item, index) => (
                                                                <Link to={items.post_permalink} >
                                                                    <div className="overlay">
                                                                       <img src={items.card_image[0]} className="image-building-image" alt={"New homes in " + items.card_image_title[0]} title={items.post_title} />
                                                                    </div>
                                                                </Link>
                                                            ))}
                                                        </OwlCarousel>
                                                    )
                                            ) : ('')}
                                        </div>
                                        <div className="plan-img-info">
                                            {/* <span className="card-snipe">Promotion Snipe Here</span> */}
                                            {items.promotion_info ? (
                                                items.promotion_info.promo_snipe ? (
                                                    <a href={items.promotion_info.promo_link}><span className="card-snipe">{items.promotion_info.promo_snipe}</span></a>
                                                ) : (
                                                        items.promotion_info.promo_icon ? (
                                                            <a href={items.promotion_info.promo_link}><span className="promo-icon"><img src={items.promotion_info.promo_icon} alt="promo_icon" title="promo_icon"/></span></a>
                                                        ) : ('')
                                                    )
                                            ) : ('')}
                                            <div className="card-img-info">
                                                {items.post_status ? (
                                                    <span className="card-status">{items.post_status}</span>
                                                ) : ('')}
                                                {items.post_title ? (
                                                    <h4 className="card-name">{items.post_title}</h4>
                                                ) : ('')}
                                                <p className="card-price">{items.city}
                                                    {items.city && items.state ? (
                                                        ', '
                                                    ) : ('')}
                                                    {items.state}
                                                    {(items.city || items.state) && items.price_value && items.post_status !== "Sold" ? (
                                                        " | "
                                                    ) : ('')}
                                                    {items.price_value && items.post_status !== "Sold" ? (
                                                        items.price_value
                                                    ) : ('')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-info">
                                        <ul className="card-detail">
                                            {items.square_feet ? (
                                                <li>
                                                    <span className="info-value">{items.square_feet}</span>
                                                    <span className="info-name">Sq.{'\u00A0'}Footage</span>
                                                </li>
                                            ) : ('')}
                                            {items.bed ? (
                                                <li>
                                                    <span className="info-value">{items.bed}</span>
                                                    <span className="info-name">
                                                        {items.bed === '1' ? ('Bed') : ('Beds')}
                                                    </span>
                                                </li>
                                            ) : ('')}
                                            {items.bath ? (
                                                <li>
                                                    <span className="info-value">{items.bath}</span>
                                                    <span className="info-name">
                                                        {items.bath === '1' ? ('Bath') : ('Baths')}
                                                    </span>
                                                </li>
                                            ) : ('')}
                                            {items.stories ? (
                                                <li>
                                                    <span className="info-value">{items.stories}</span>
                                                    <span className="info-name">
                                                        {items.stories === '1' ? (' Story') : (' Stories')}
                                                    </span>
                                                </li>
                                            ) : ('')}
                                        </ul>
                                        {/* <Link to="#" className="learn-more">View Details</Link> */}
                                    </div>
                                    <div className="card-hover-content">
                                        <div class="qmi-card-content">
                                            {items.post_title ? (
                                                <h4 className="card-name">{items.post_title}</h4>
                                            ) : ('')}
                                            {/* <div class="card-text">Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs.</div> */}
                                            {items.long_description ? (
                                                toShow = items.long_description.substring(0, 150) + "...",
                                                <div
                                                    className="card-text" dangerouslySetInnerHTML={{ __html: toShow.replace(/(?:\r\n|\r|\n)/g, '') }}></div>
                                            ) : ('')}
                                            <Link to={items.post_permalink}
    
                                                className="learn-more btn-color">LEARN MORE</Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>,
                this.state.visible < Qmi_detail.length ? (
                    <button onClick={this.loadMore} type="button" className="load-more fyh_load_more"><span>Load more</span><img id="load_more_loader" src={require('../../assets/images/load_more_button.svg').default} /></button>
                ) : ("")
            ] : ['']

        );
    }
}

export default QmiCard;