import React from 'react';
import './GenericBlock.scss';
import HeadingSection from './HeadingSection';
class GenericBlock extends React.Component {

    render() {
        var generic_block = this.props.generic_block;
        var hide_component = this.props.hide_component;
        var target_change ;
        if(generic_block.anchor_in_new_tab==true){
target_change="_blank";
        }
        else{
            target_change="";
        }
        var backgroundImage = generic_block.side_border;
        var borderTop = generic_block.bordertop;
        var borderBottom = generic_block.borderbottom;
        if (backgroundImage !== "") {
            var background_image = {
                backgroundImage: 'url(' + backgroundImage + ')',
            }
        }
        return (
            <section class = "generic-content-block">
                <div className="container">
                    {hide_component !== true && generic_block.image.url && generic_block.headline && generic_block.description ? (
                    <div className="row generic-main-div" style={{ backgroundColor: generic_block.background_color }}>
                        {generic_block.image_position === "right" ? [
                            <div className="generic-content divide-equal" style={background_image}>
                                <div className="generic-content-section">
                                    <HeadingSection
                                        subtitle={generic_block.sub_text}
                                        subtitle_color={generic_block.subtitle_color}
                                        title_part_1={generic_block.headline}
                                        title_part_2={generic_block.headline_part_2}
                                        headline_part_1_color={generic_block.headline_part_1_color}
                                        headline_part_2_color={generic_block.headline_part_2_color}
                                    />
                                    {generic_block.description ? (
                                        <p className="generic-description" dangerouslySetInnerHTML={{ __html: generic_block.description.replace(/(?:\r\n|\r|\n)/g, '') }}></p>
                                    ) : ('')}
                                    {generic_block.cta_label && generic_block.cta_link ? (
                                            <a href={generic_block.cta_link} target={target_change} className="generic-button btn-color" title={generic_block.cta_label} >{generic_block.cta_label}</a>
                                    ) : ('')}
                                </div>
                            </div>,
                            generic_block.image.url ? (
                                <div className="generic-image divide-equal">
                                    <img src={generic_block.image.url} title={generic_block.image.title} alt={generic_block.image.title} className="generic-block-image" />
                                </div>
                            ) : ('')
                        ] : [
                                generic_block.image.url ? (
                                    <div className="generic-image divide-equal">
                                        <img src={generic_block.image.url} title={generic_block.image.title} alt={generic_block.image.title} className="generic-block-image" />
                                    </div>
                                ) : (''),

                                <div className="generic-content divide-equal" style={{ backgroundImage: generic_block.sideImage }}>
                                    <div className="generic-content-section">
                                        <HeadingSection
                                            subtitle={generic_block.sub_text}
                                            title_part_1={generic_block.headline}
                                            title_part_2={generic_block.headline_part_2}
                                            headline_part_1_color={generic_block.headline_part_1_color}
                                            headline_part_2_color={generic_block.headline_part_2_color} 
                                        />
                                        {generic_block.description ? (
                                            <p className="generic-description" dangerouslySetInnerHTML={{ __html: generic_block.description.replace(/(?:\r\n|\r|\n)/g, '') }}></p>
                                        ) : ('')}
                                        {generic_block.cta_label && generic_block.cta_link ? (
                                            <a href={generic_block.cta_link} target={target_change} className="generic-button btn-color" title={generic_block.cta_label}>{generic_block.cta_label}</a>
                                        ) : ('')}
                                    </div>
                                </div>
                            ]}
                    </div>
                    ):('')}
                </div> </section>
        );
    }
}

export default GenericBlock;

