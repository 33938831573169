import React from 'react'
import fancybox from '@fancyapps/fancybox' // eslint-disable-line no-unused-vars
import './ScheduleTourForm.scss';
import $ from 'jquery';
//import Cookies from 'js-cookie';
import cookie from 'react-cookies';
import logo from '../../assets/images/logo.png';

class ScheduleTourForm extends React.Component {

    TourloadMarketoForm() {
        var that = this;
        const zillow_hour = that.props.form_details.zillow_hour;
        const title = that.props.form_details.title;
        const holidays = that.props.form_details.holidays;
        const map_directions_link = that.props.form_details.map_directions_link;
        const community_id = that.props.form_details.community_id;
        const crm_salesforce_id = that.props.form_details.crm_salesforce_id;
        const division_name = this.props.division_name;
        // const post_type = that.props.post.post_type;
        const ID = that.props.form_details.ID;
        const cookie_value = this.state.mkto_cookie;

        if (window.MktoForms2 !== "undefined") {
            window.MktoForms2.loadForm("//app-sj10.marketo.com", "167-BTV-942", 4381, function (form) {

                form.onSuccess(function (values, followUpUrl) {
                    var useremail = values.Email;
                    var cookieexpiry = new Date();
                    cookieexpiry.setFullYear(cookieexpiry.getFullYear() + 2);

                    //set cookie
                    document.cookie="_mkto_email="+useremail+"; expires="+cookieexpiry+"; path=/";
                    return false;
                });
                window.JQUERY('.headtxt').html(title);
                window.JQUERY('#tour_form .logo > a:first-child').attr({ href: window.location.origin, title:"Peacn Ridge", alt:"Peacn Ridge" });
                window.JQUERY('#tour_form .logo img').attr({ src: logo, title:"Peacn Ridge", alt:"Peacn Ridge" });
                window.JQUERY('#tour_form #LblmktoRadio_88768_0 .appointment img').attr({ title: "On-Site Appointment"});
                window.JQUERY('#tour_form #LblmktoRadio_88768_1 .appointment img').attr({ title: "Virtual Appointment"});

                if (map_directions_link !== "") {
                    window.JQUERY('#DriveDir a').attr("target", "_blank");
                    window.JQUERY('#DriveDir a').attr("href", map_directions_link);
                } else {
                    window.JQUERY('#DriveDir').hide();
                }

                window.JQUERY('#mktoForm_4381 a#PrivacyLink').attr("href", window.location.origin + "/privacy-policy/");
                window.JQUERY('#mktoForm_4381 input[name="External_Lead_Id__c"]').val(community_id);

                var date_trigger = setInterval(function () {
                    if (window.jQuery('#mktoForm_4381 #requestedDate').val().length > 0) {
                        window.JQUERY("#mktoForm_4381 #requestedDate").trigger("change");
                        clearInterval(date_trigger);
                    }
                }, 200);
                window.JQUERY("#mktoForm_4381 #requestedDate").change(function () {

                    
                    if(holidays.length > 0){
                        window.JQUERY('#mktoForm_4381 #requestedDate').datepicker('option', 'beforeShowDay', function(date){
                            var string = window.JQUERY.datepicker.formatDate('dd/mm/yy', date);
                            if(holidays.indexOf(string) == -1){
                                return [true, ""];
                            }
                            else {
                                return [false, 'national_holiday_date'];
                            }
                        });
                    }

                    window.JQUERY("#mktoForm_4381 #apptRequestedTime option").each(function () {
                        window.JQUERY(this).remove();
                    });
                    var addHour = ["10:00 am", "11:00 am", "12:00 pm", "01:00 pm", "02:00 pm", "03:00 pm", "04:00 pm", "05:00 pm"];
                    window.JQUERY.each(addHour, function (index, value) {
                        window.JQUERY("#mktoForm_4381 #apptRequestedTime").append(window.JQUERY("<option>", {
                            value: value,
                            text: value
                        }));
                    });
                    var requestedDate = window.JQUERY('#mktoForm_4381 #requestedDate').val();
                    var data = "";
                    var data = {
                        action: 'get_shedule_hour',
                        requestedDate: requestedDate,
                        n_id: ID,
                        result: zillow_hour
                    };

                    if (requestedDate !== '') {
                        window.JQUERY.ajax({
                            type: 'POST',
                            data: data,
                            url: "/wp-admin/admin-ajax.php",
                            success: function (response) {

                                response = JSON.parse(response);

                                if (response.close_day == 'true') {
                                    if (window.JQUERY('#mktoForm_4381 .Row_5:has(p)')) {
                                        window.JQUERY('#mktoForm_4381 .Row_5 p').remove();
                                    }
                                    window.JQUERY('#mktoForm_4381 .Row_5').append('<p class="red-error">Appointments are not available for this date.</p>');
                                    window.JQUERY('#mktoForm_4381 .Row_7 a').css('pointer-events', 'none');
                                } else {
                                    window.JQUERY('#mktoForm_4381 .Row_7 a').css('pointer-events', 'unset');
                                    window.JQUERY('#mktoForm_4381 .Row_5 p').remove();
                                }

                                var startTime = 0;
                                var option_length = window.JQUERY('#mktoForm_4381 #apptRequestedTime > option').length;
                                window.JQUERY("#mktoForm_4381 #apptRequestedTime option").each(function () {

                                    if (window.JQUERY(this).val() !== response.start_time) {
                                        window.JQUERY(this).remove();
                                        startTime++;
                                        if (startTime == option_length) {
                                            window.JQUERY.each(addHour, function (index, value) {
                                                window.JQUERY("#mktoForm_4381 #apptRequestedTime").append(window.JQUERY("<option>", {
                                                    value: value,
                                                    text: value
                                                }));
                                            });
                                            window.JQUERY("#mktoForm_4381 #apptRequestedTime").val('10:00 am');
                                        }
                                    } else {
                                        window.JQUERY("#mktoForm_4381 #apptRequestedTime").val(response.start_time);
                                        return false;
                                    }
                                });
                                var endtime = false; var k = 1;
                                window.JQUERY("#mktoForm_4381 #apptRequestedTime option").each(function () {
                                    if (window.JQUERY(this).val() == response.end_time || endtime == true) {
                                        endtime = true;
                                        k++;
                                        if (k !== 2) { window.JQUERY(this).remove(); }
                                    }
                                });
                            }
                        });
                    }
                });

            });

            window.MktoForms2.whenReady(function (form) {

                window.JQUERY("#mktoForm_4381").find('[name="Communities_of_Interest__c"]').val(crm_salesforce_id);
                window.JQUERY("#mktoForm_4381").find('[name="formURL"]').val(window.location.href);
                window.JQUERY("#mktoForm_4381").find('[name="Division__c"]').val(division_name);
                window.JQUERY("#mktoForm_4381").find('[name="communityName"]').val(title);

                var formfields = form.vals();
                var formfields_names = Object.keys(formfields);

                var formfilterValue = cookie_value;
                console.log('Cookie value: ' + formfilterValue);
                var outputArray = [];
                for (var i = 0; i < formfields_names.length; i++) {
                    if (formfields_names[i] && formfields_names[i] != 'munchkinId' && formfields_names[i] != 'formid') {
                        if ((window.JQUERY.inArray(formfields_names[i], outputArray)) == -1) {
                            outputArray.push(formfields_names[i]);
                        }
                    }
                }
                const reducedArray = outputArray.reduce((fields_names, feilds) => `${fields_names}${feilds},`, '')
                var data = "";
                var data = {
                    action: 'formnames',
                    requestData: reducedArray,
                    filterType: 'cookies',
                    filterValue: formfilterValue
                };
                window.JQUERY.ajax({
                    type: 'POST',
                    data: data,
                    url: "/wp-admin/admin-ajax.php",
                    success: function (response) {
                        var field_havevalues = [];
                        var results = [];
                        results = JSON.parse(response);
                      
                        //results = JSON.parse(JSON.stringify(response));
                        //var dissatisfaction = results.dissatisfaction__c;
                        // var value_here = Array.from(dissatisfaction.split(';'));
                        var finalvaluestofill = {};
                        window.JQUERY.each(formfields_names, function (value, elem) {
                            if (elem) {
                                var keys = elem.toLowerCase();
                                if (keys in results) {
                                    if (results[keys] && results[keys] != '') {
                                        finalvaluestofill[elem] = results[keys];
                                        field_havevalues.push(elem);
                                    }
                                }
                            }
                        });

                        if (finalvaluestofill) {
                            form.vals(finalvaluestofill);
                        }
                    }
                });


            });
        }

    }

    componentDidMount() {
        this.state = {
            mkto_cookie: cookie.load('_mkto_trk')
        };
        this.TourloadMarketoForm();
        if (window.location.href.indexOf("#tour-form") > -1) {
            document.getElementById("tour_click_btn").click();
        }
    }
    componentWillUnmount(){         
        $('#ui-datepicker-div').remove();          
    }
    render() {
        var pattern_image = this.props.form_details.pattern_image;
        var thumbnail_image = this.props.form_details.tour_post_thumbnail;
        var tour_post_title = this.props.form_details.title;
        return (

            <div id="tour_form">
                <div className="tourpop_form" >
                    <div className="form-column">
                        <form id="mktoForm_4381"></form>
                    </div>
                    <div className="form-image">

                        {pattern_image ? (
                            <div className="two_image">
                                <img src={pattern_image.url} alt={tour_post_title} title={tour_post_title} />
                            </div>
                        ) : ('')
                        }

                        <picture><img src={thumbnail_image} alt={tour_post_title} title={tour_post_title} /></picture>
                    </div>
                </div>
            </div>
        )
    }
}

export default ScheduleTourForm