import React from 'react';
import './SidebarForm.scss';
import { Modal } from 'react-bootstrap';
import SidebarSignUp from './SidebarSignUp.jsx';
import $ from 'jquery'

class SidebarForm extends React.Component {  

     constructor(props) {
          super(props);
          this.state = {
               show: null
           };
           this.handleShow = this.handleShow.bind(this);
           this.handleClose = this.handleClose.bind(this);
          //  this.load_sfdc_chat = this.load_sfdc_chat.bind(this)
          
      }
      handleClose() {
          this.setState({ show: null });
      }
  
      handleShow(id) {
          this.setState({ show: id });
      }
     // componentDidMount() {
     //      window.SHOW_CHAT = true;
     //      if(window.SHOW_CHAT){
     //           window.JQUERY(".embeddedServiceHelpButton").show();
     //           window.JQUERY(".embeddedServiceSidebar").show();
     //           this.load_sfdc_chat();
     //      }
     // }

     // load_sfdc_chat(){

     //      var that = this;  
     //      console.log('Chat Details');
     //      console.log(that.props.sfdc_chat_details);   
     //      var initESW = function(gslbBaseURL) {
     //           //window.SFDC_COMMID = that.props;
     //           window.embedded_svc.settings.displayHelpButton = true; //Or false
     //           window.embedded_svc.settings.language = ''; //For example, enter 'en' or 'en-US'
           
     //           window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
     //           window.embedded_svc.settings.entryFeature = 'LiveAgent';

     //           console.log('Chat Properties');
     //           console.log(window.embedded_svc);

     //           window.embedded_svc.settings.iframeURL = 'https://service.force.com/embeddedservice/5.0/esw.html?parent='+window.location.href;

     //           // window.embedded_svc.settings.targetElement = document.getElementsByClassName('sidebar-agent-info')[0];
           
     //           window.embedded_svc.settings.extraPrechatFormDetails = [
     //                {"label": "First Name", "transcriptFields": ["ContactFirstName__c"]},
     //                {"label": "Last Name", "transcriptFields": ["ContactLastName__c"]},
     //                {"label": "Email", "transcriptFields": ["ContactEmail__c"]},
     //                {"value": that.props.sfdc_chat_details.sfdc_community_id, "transcriptFields": ["CommunityCRMId__c"]},
     //                {"value": window.location.href, "transcriptFields": ["WebsiteURL__c"]},
     //                {"label": "Question", "transcriptFields": ["ContactQuestion__c"]},
     //                {"label": "Preferred Phone", "transcriptFields": ["ContactPhone__c"]}, 
     //                {"value": that.props.sfdc_chat_details.sfdc_deployment_id, "transcriptFields": ["LiveChatDeployment"]},
     //                {"value": that.props.sfdc_chat_details.sfdc_button_id, "transcriptFields": ["LiveChatButton"]},
     //                {"value": that.props.sfdc_chat_details.sfdc_eswliveagent_devname, "transcriptFields": ["ESWLiveAgentDevName__c"]},
     //                {"value": that.props.sfdc_chat_details.sfdc_embedded_chat_group, "transcriptFields": ["EmbeddedServiceDepName__c"]}
     //           ];
               
     //               //Added below code for not creating new Contact in the system if no match found for contact in Salesforce 
     //               window.embedded_svc.settings.extraPrechatInfo = [
     //                   {"entityFieldMaps":[
     //                       {"doCreate":false,"doFind":true,"fieldName":"LastName","isExactMatch":false,"label":"Last Name"},
     //                       {"doCreate":false,"doFind":true,"fieldName":"FirstName","isExactMatch":false,"label":"First Name"}, 
     //                       {"doCreate":false,"doFind":true,"fieldName":"Email","isExactMatch":false,"label":"Email"}],
     //                       "entityName":"Contact","showOnCreate":false}
     //               ];
               
     //               window.embedded_svc.init(
     //                'https://tripointegroup--sandbox.my.salesforce.com',
     //                'https://sandbox-tripointegroup.cs43.force.com',
     //                gslbBaseURL,
     //                '00D63000000Axms',
     //                that.props.sfdc_chat_details.sfdc_embedded_chat_group,
     //                {
     //                baseLiveAgentContentURL: 'https://c.la1-c1cs-ph2.salesforceliveagent.com/content',
     //                deploymentId: that.props.sfdc_chat_details.sfdc_deployment_id,
     //                buttonId: that.props.sfdc_chat_details.sfdc_button_id,
     //                baseLiveAgentURL: 'https://d.la1-c1cs-ph2.salesforceliveagent.com/chat',
     //                eswLiveAgentDevName: that.props.sfdc_chat_details.sfdc_eswliveagent_devname,
     //                isOfflineSupportEnabled: false
     //                }
     //                );
     //         };
           
     //         if (!window.embedded_svc) {
     //           var s = document.createElement('script');
     //           s.setAttribute('src', 'https://tripointegroup--sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js');
     //           s.onload = function() {
     //             initESW(null);
     //           };
     //           document.body.appendChild(s);
     //         } else {
     //           initESW('https://service.force.com');
     //         }
                
     // }
     componentDidMount() {
          if(window.location.href.indexOf("#interest-form") > -1) {
              document.getElementById("interest_click_btn").click();
          }
      }
     calltoaction_data_layer_push(param) {
        this.setState({ value:param })
        // if(param && param==='clickToCall') {
        //     window.dataLayer.push({'event':'clickToCall'});
        // }
     }
  render() {    
     var agent_info = this.props.agent_info; 
     var window_width = window.screen.width;
     var posttype = this.props.posttype; 
     var community_data = this.props.community_data; 
     var post_status = this.props.post_status; 
     var tour_btn = this.props.tour_btn.show_tour_btn;
 
     return (  
          <div className="sidebar-form">  
               <div className="sidebar-close mobile-close"></div>
               <div className="sidebar-agent-info">
                    {agent_info.agent_nhs_heading ? (
                         <p className="sidebar-title">{agent_info.agent_nhs_heading}</p>
                    ):(
                         <p className="sidebar-title">New Home Specialist</p>
                    )}
                    {agent_info.agents_contact_name ? (
                         <p className="agent-name">{agent_info.agents_contact_name} {agent_info.agent_heading}</p>
                    ):('')}
                    <div className="sidebar-btn">
                         {(window_width < 767) ? [
                              posttype === 'qmi' ?[
                                   tour_btn && post_status !== 'Coming Soon' ? [
                                        <a href="javascript:void(0)" data-fancybox-trigger="tour_form"
                                        className="filled-btn">{this.props.schedule_button_text}</a>,
                                        agent_info.agents_contact_phone ? (
                                             <p className="small-gray-text">--or--</p>
                                        ):('')
                                   ]:[
                                        <a href="javascript:void(0)" className="filled-btn" title="Join The Interest List" onClick={() => this.handleShow("Request_quote")} id="interest_click_btn">Join The Interest List</a>,
                                        agent_info.agents_contact_phone ? (
                                             <p className="small-gray-text">--or--</p>
                                        ):(''),
                                        <Modal className="Estimate_modal Request_quote" show={this.state.show === "Request_quote"} onHide={this.handleClose} >
                                             <Modal.Header closeButton></Modal.Header>
                                             <Modal.Body>
                                                  <SidebarSignUp sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={community_data.neigh_title} division_name={community_data.division_name}/>
                                             </Modal.Body>
                                        </Modal>
                                   ]
                              ]:[ 
                                   tour_btn && post_status !== 'Coming Soon' ? [
                                        <a href="javascript:void(0)" className="filled-btn" 
                                        data-fancybox-trigger="tour_form" title="schedule tour"
                                        >Schedule a Tour</a>,
                                        agent_info.agents_contact_phone ? (
                                             <p className="small-gray-text">--or--</p>
                                        ):('')
                                   ]:[
                                        <a href="javascript:void(0)" className="filled-btn" title="Join The Interest List" id="interest_click_btn" onClick={() => this.handleShow("Request_quote")}>Join The Interest List</a>,
                                        agent_info.agents_contact_phone ? (
                                             <p className="small-gray-text">--or--</p>
                                        ):(''),
                                        <Modal className="Estimate_modal Request_quote" show={this.state.show === "Request_quote"} onHide={this.handleClose} >
                                             <Modal.Header closeButton></Modal.Header>
                                             <Modal.Body>
                                                  <SidebarSignUp sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={community_data.title} division_name={community_data.division_name}/>
                                             </Modal.Body>
                                        </Modal>
                                   ]
                              ]
                         ]:[
                              <a href="javascript:void(0)" className="filled-btn" title="Join The Interest List" onClick={() => this.handleShow("Request_quote")} id="interest_click_btn">Join The Interest List</a>,
                              agent_info.agents_contact_phone ? (
                                   <p className="small-gray-text">--or--</p>
                              ):(''),
                              <Modal className="Estimate_modal Request_quote" show={this.state.show === "Request_quote"} onHide={this.handleClose} >
                                   <Modal.Header closeButton></Modal.Header>
                                   <Modal.Body>
                                   {posttype === 'qmi' ? (
                                        <SidebarSignUp sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={community_data.neigh_title} division_name={community_data.division_name}/>
                                   ):(
                                        <SidebarSignUp sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={community_data.title} division_name={community_data.division_name}/>
                                   )}
                                   </Modal.Body>
                              </Modal>
                         ]}
                     
                         {agent_info.agents_contact_phone ? (
                              <a href={"tel:"+agent_info.agents_contact_phone} className="phone-num" title="phone number" onClick={() => this.calltoaction_data_layer_push('clickToCall')}>{agent_info.agents_contact_phone}</a>
                         ):('')}
                    </div>
                    {agent_info.dre_license ? (
                         <p className="small-gray-text dre-num">{agent_info.dre_license}</p>
                    ):('')}
               </div>
          </div>  
     );  
  }  
}  

export default SidebarForm; 
