import React from 'react';
import axios from 'axios';
import SubNavbar from '../../Components/SubNavbar';
import CommBanner from '../../Components/CommBanner';
import UtilityNav from '../../Components/UtilityNav';
import PromotionBlock from '../../Components/PromotionBlock';
import GlobalField from '../../Components/GlobalField';
import FaqSection from '../../Components/FaqSection';
import { Redirect } from "react-router-dom";
import {apiConfig} from '../../Components/Helper.jsx';
import NoMatch from './NoMatch';
import NhsDesktop from '../../Components/NhsDesktop';
import TPHLoader from '../../Components/TPHLoader.jsx';
import DocumentMeta from 'react-document-meta';
import $ from 'jquery';
class QmiPage extends React.Component {  
  constructor() {
    super();
    this.state = {
      qmi_component : '',
      success : false,
    };
  }
  fetch_data(){
    axios.get(apiConfig().API_URL+'/qmi_info/'+this.props.slug)
    .then(response => {
      
      this.setState({
        qmi_component : response.data.data,
        success: response.data.success,
      })
      console.log(response);
    })
  }
  /*Local Business*/
  local_business_structured_data(qmiComponent){
    if(qmiComponent) {

      if(qmiComponent.agent_info.agents_contact_phone){
        var telephone = qmiComponent.agent_info.agents_contact_phone;
      }
      if(qmiComponent.min_price){
        var priceRange = qmiComponent.min_price;
      }
      if(qmiComponent.physical_address.physical_address_heading){
        var addressLocality = qmiComponent.physical_address.physical_address_heading;
      }
      if(qmiComponent.streetAddress){
        var streetAddress = qmiComponent.streetAddress;
      }
      if(qmiComponent.postalCode){
        var postalCode = qmiComponent.postalCode;
      }
      if(qmiComponent.latitude){
        var latitude = qmiComponent.latitude;
      }
      if(qmiComponent.longitude){
        var longitude = qmiComponent.longitude;
      }
      if(qmiComponent.state_abb){
        var state_abb = qmiComponent.state_abb.toUpperCase();
      }
      if(qmiComponent.site_logo){
        var site_logo = qmiComponent.site_logo;
      }
  
    }
    const script = document.createElement("script");
    // Structure Data script start 
    script.type = "application/ld+json";
    script.innerHTML = `{
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      "name": "Pecan Ridge",
      "image": "${site_logo}",
      "@id": "",
      "url": "${window.location.href}",
      "telephone": "${telephone}",
      "priceRange": "${priceRange}",
      "address": {
      "@type": "PostalAddress",
      "streetAddress": "${streetAddress}",
      "addressLocality": "${addressLocality}",
      "addressRegion": "${state_abb}",
      "postalCode": "${postalCode}",
      "addressCountry": "US"
      },
      "geo": {
      "@type": "GeoCoordinates",
      "latitude": "${latitude}",
      "longitude": "${longitude}"
      }  
    }`
      //  Structure Data script end 
      document.head.appendChild(script);

  }
	componentDidMount(){
    this.fetch_data();
   window.CallTrk.swap();
   this.globalstylefun();  
    window.scrollTo(0,0);
    window.JQUERY('.top-scroll').addClass('dynamic');
  }
  globalstylefun = () => {
    const globalstyle = window.adminStyle;
      if (globalstyle) {
        $('h2, .sectiontitle-headline').css({ "color": globalstyle.global_components_headline, "font-family": globalstyle.h2_fonts});
        $('h1').css({ "color": globalstyle.global_components_headline_h1, "font-family": globalstyle.h1_fonts });
        $('p').css({ "color": globalstyle.global_paragraph_text, "font-family": globalstyle.paragraph_fonts });
        $('.sub-title, .sectiontitle-eyebrow_text, .blog_card_category, .brand_heading').css("color", globalstyle.eyebrow_text_color);
        $('.btn-color').css("color", globalstyle.button_text_color);
        $('.btn-color').css({ "background-color": globalstyle.button_outlinebackground_color, "border": '1px solid' + globalstyle.button_outlinebackground_color });
        $('.cta_label_color').css({ "color": globalstyle.inline_linkscta, 'border-bottom': '2px solid' + globalstyle.inline_linkscta });
        $(".btn-color").mouseover(function () {
          $(this).css({ "background-color": globalstyle.button_hover, "border": '1px solid' + globalstyle.button_hover });
        }).mouseout(function () {
          $(this).css({ "background-color": globalstyle.button_outlinebackground_color, "border": '1px solid' + globalstyle.button_outlinebackground_color });
        });
      } 
  }
  componentDidUpdate(){
    if(this.state.success == true){
    //   window.dataLayer.push({
    //     'event': 'communityId',
    //     'divisionName': this.state.qmi_component.qmi_detail.neigh_title ? this.state.qmi_component.qmi_detail.neigh_title : "",
    //     'e1CommunityId': this.state.qmi_component.qmi_detail.e1_community_id ? this.state.qmi_component.qmi_detail.e1_community_id : "",
    //     'salesforceId': this.state.qmi_component.qmi_detail.communityOfInterest ? this.state.qmi_component.qmi_detail.communityOfInterest : "",
    //     'page_type': 'MoveInReadyHomesite'
    // });
    // window.dataLayer.push({'event': 'qmiPageviews'});
  }
    this.globalstylefun();
    window.CallTrk.swap();
    window.scrollTo(0,0);
    }

  render() {    
    var window_width = window.screen.width;
    var qmi_data = this.state.qmi_component;
    var qmi_component = this.state.qmi_component.qmi_detail;
    var success = this.state.success; 
    var message = this.state.message; 
    var state_name = this.props.state.toLowerCase();
    var submarket_name = this.props.submarket.toLowerCase();
    var qmiComponent = this.state.qmi_component.qmi_detail;

    const meta = {
      title: qmi_data.yoast_title,
      description: qmi_data.yoast_desc,
      canonical: window.location.href
    };
    if(qmiComponent){
      this.local_business_structured_data(qmiComponent);
    }
     return (
      <> 
       <DocumentMeta {...meta} />
        <div className="Qmi-main">  
            {success === true ? [
              state_name === qmi_component.state_abb.toLowerCase() && submarket_name=== qmi_component.submarket.toLowerCase() ? [
                qmi_component.qmi_status !== "Archive" ? [
                    <SubNavbar navbar_detail={qmi_component} posttype="qmi"/>,

                    qmi_component ? (
                      <CommBanner community_details={qmi_component} posttype="qmi"/>
                    ):(''),

                    qmi_component.promotion_info ? (
                      <PromotionBlock promo_data={qmi_component.promotion_info} />
                    ):(''),

                    <GlobalField slug={this.props.slug} global_data={qmi_component.component}/>,

                    <FaqSection community_details={qmi_component}/>,

                    (window_width < 767) ? (  
                      <UtilityNav sfdc_chat={qmi_component.sfdc_chat_details} agent_info={qmi_component.agent_info} community_component={qmi_component} posttype="qmi"/>
                    ):(
                      <NhsDesktop sfdc_chat={qmi_component.sfdc_chat_details} agent_info={qmi_component.agent_info} community_component={qmi_component} posttype="qmi"/>
                    )
                ]:[<Redirect to={qmi_component.comm_permalink} />]
              ]:[ <Redirect to={<NoMatch/>} />  ]
            ]:[message != 'Not Completed' ?  <TPHLoader/>  :  <Redirect to={<NoMatch/>} />]}
          </div> 
      </>  
     );  
  }  
}  

export default QmiPage; 
