import React from 'react';
import $ from 'jquery';
import './Blogs.scss';
import { Link } from 'react-router-dom'
class LifestyleListing extends React.Component {

  render() {
    const lifestyle_listing  = this.props.lifestyle_data;
    var toShow;
    var window_width = window.screen.width;  
    return !lifestyle_listing || !lifestyle_listing.length ? null :
    (
            <div className="blog_listing_container lifestyle-global-div blogs-section">
             <div className="card_heading_content">
                  <h4 className="brand_heading">explore more at altis</h4>
              </div>
              <div className="blog_card_container">
                {lifestyle_listing.map((item, i) => (
                    <div className="blog_card" key={i}>
                      {item.post_thumbnail_url ? (
                      <Link to={item.post_url}>
                        <div className="blog_card_img">
                          <img src={item.post_thumbnail_url} title={item.post_title} alt={item.post_title} />
                        </div>
                      </Link>
                       ) : ('')}
                      <div className="blog_card_content">
                      {item.cat_name ? (
                          <div className="blog_card_category">
                            {item.cat_name.replace("&amp;", "&")}
                          </div>
                        ) : ('')}
                        {item.post_title ? (
                          <div className="blog_card_content_caption">
                            <h4>{item.post_title}</h4>
                          </div>
                        ) : ('')}
                        {item.post_content ? (
                          toShow = item.post_content.substring(0, 150) + "...",
                          <div className="blog_card_content_para">
                            <p dangerouslySetInnerHTML={{ __html: toShow.replace(/(?:\r\n|\r|\n)/g, '') }} />
                          </div>
                        ) : ('')}
                        {item.post_url ? (
                          <div className="read_more_btn">
                            <Link to={item.post_url} className="read_more">Read More</Link>
                          </div>
                        ) : ('')}
                      </div>
                    </div>
                ))}
              </div>
            
            </div>
      );
  }
}

export default LifestyleListing;