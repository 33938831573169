import React from 'react';
import './Residence.scss';
import { Modal , Form} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MortgageSection from './MortgageSection';
import $, { event } from 'jquery';
import defaultImg from '../../assets/images/TPH-default-image2.gif';
class ResidenceScreen6 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: null,
            name: '',
            // value:'',
            saved:false
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.contactSubmit = this.contactSubmit.bind(this);
        this.name = React.createRef();
    }

    handleClose() {
        this.setState({ show: null });
    }

    handleShow(id) {
        this.setState({ show: id });
        // if(id && id==='Estimate') {
        //     window.dataLayer.push({'event':'mortgageCalculator'});
        // }
    }
    componentDidMount() {
        $('.residence-header').removeClass('bdx-added');
    }
    contactSubmit(e){
        e.preventDefault();
        if((this.name.current.value).length > 23){
            this.setState({saved: true});
        }else{
            $('.gray-text').css('color','red');
        }
      
    }

    componentDidMount(){
        window.floorplan_name = this.props.residence_component.common_detail.res_title;
    }

    floorplan_name_save(){
        window.floorplan_name = document.getElementById("floorplan_name").value
    }

    render() {
        var residence_component = this.props.residence_component;
        return (
            <div className="residence-details" id="screen-6">
                <div className="residence-image"> 
                    <div className="image-column"> 
                        { residence_component.summary.summary_image ? (
                            <img src={residence_component.summary.summary_image.url} alt={residence_component.summary.summary_image.title} />
                        ):(
                            <img src={defaultImg} alt="defaultImg" title="defaultImg"/>
                        ) }
                    </div>
                    <div className="detail-column">
                        <div className='form-main'>
                            <h2 className='form-heading'>Name Your Custom Home</h2>
                            {this.state.saved === false ? (
                                <Form className="residence-input" onSubmit= {this.contactSubmit}>
                                    <Form.Group>
                                        <Form.Control id="floorplan_name"  type="text" ref={this.name} onChange={this.floorplan_name_save} defaultValue = {residence_component.common_detail.res_title} required/>
                                    </Form.Group>
                                </Form>
                            ):('')}
                            {this.state.saved === true ? (
                                <p>{this.name.current.value} is Saved.</p>
                            ):('')}
                        </div>
                    </div>
                </div>
                {/* <div className="residence-content">
                    <h1 className="plan-name">Summary</h1>
                    {residence_component.summary.summary_subtext ? (
                        <div className="plan-description">
                            <p>{residence_component.summary.summary_subtext}</p>
                        </div>
                    ):('')} */}
                    {/* <ul className="estimate-data">
                       <li>
                           <span>Elevation</span>
                           <span>Desert Modern</span>
                       </li>
                       <li>
                           <span>Floorplan</span>
                           <span>Plan 1B</span>
                           <ul className="sub-estimate-list">
                                <li>
                                    <span>Loft</span>
                                    <span>Included</span>
                                </li>
                                <li>
                                    <span>Rooftop Deck</span>
                                    <span>Included</span>
                                </li>
                            </ul>
                       </li>
                       <li>
                           <span>Kitchen/bath</span>
                           <span>Coustmize</span>
                           <ul className="sub-estimate-list">
                                <li>
                                    <span>Loft</span>
                                    <span>Included</span>
                                </li>
                                <li>
                                    <span>Rooftop Deck</span>
                                    <span>Included</span>
                                </li>
                            </ul>
                       </li>
                    </ul> */}
                    {/* <div className="estimate-price">
                        {residence_component.summary.base_price && residence_component.common_detail.post_status !== "Sold Out" && residence_component.common_detail.post_status !== "Temporarily Sold Out"  && residence_component.summary.hide_price !=1 ? (
                            <div className="total-price">
                                <span>Estimated Total</span>
                                <span>{residence_component.summary.base_price}</span>
                            </div>
                         ):('')}
                        <a href="javascript:void(0)" title="Estimate" className="estimate-payment" onClick={() => this.handleShow("Estimate")}>Estimate Payment</a>
                        <Modal className="Estimate_modal" show={this.state.show === "Estimate"} onHide={this.handleClose} >
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body>
                                <MortgageSection mortgage_data={residence_component.common_detail.mortgage_data}/>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div> */}
            </div>
        );
    }
}
export default ResidenceScreen6; 
