import React from 'react';
import './GenericHero.scss';

class GenericHero extends React.Component {
  
    render() {
        var generic_hero = this.props.generic_hero ;  
        var backgroundImage = generic_hero.generic_image;
        var overlay_hide = generic_hero.hide_overlay == 1 ? '':'add_overlay';
        if(backgroundImage !==""){
            var background_image = {
                backgroundImage:'url('+backgroundImage+')',
            }
        }
      
        return (
            generic_hero.headline || generic_hero.sub_text ? (
                <div className="plan-banner" style={background_image}>
                    <div className={"HeroContent d-flex "+overlay_hide}>
                        <div className="HeroContentWrapper">
                            <div className="bannerHeader">
                                <div className="heading-section">
                                    {generic_hero.headline ? (
                                        <h2 className={"main-title"} >
                                            <span dangerouslySetInnerHTML={{__html:generic_hero.headline.replace(/(?:\r\n|\r|\n)/g, '')}}></span>
                                        </h2>
                                    ):('')}
                                    {generic_hero.sub_text ?( 
                                        <p className="generic-text" >{generic_hero.sub_text}</p>
                                    ):('')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ):('')
        );  
  }  
}  

export default GenericHero; 