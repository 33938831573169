import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'
import { apiConfig } from '../../Components/Helper.jsx';
import Blogs from '../../Components/Blogs.jsx';
import TPHLoader from '../../Components/TPHLoader.jsx';
import NoMatch from './NoMatch';
import { Redirect } from "react-router-dom";
import $ from 'jquery';
import DocumentMeta from 'react-document-meta';
class BlogPage extends React.Component {
  state = {
    blog_component: [],
    success: false,
    message: '',
  }

  fetch_data() {
    axios.get(apiConfig().API_URL + '/blog_info/' + this.props.match.params.slug)
      .then(response => {
        this.setState({
          blog_component: response.data.data,
          success: response.data.success,
          message: response.data.message,
        }, () => {
          if (this.state.success == true) {
            window.adminStyle = this.state.blog_component && this.state.blog_component.global_style;
            this.globalstylefun();
          } }
        )
        console.log(response);
      })
  }

  componentDidMount() {
    this.fetch_data();
    window.globalstylefun = this.globalstylefun;
    document.addEventListener("click", function(e) {
      window.globalstylefun();
    });
    window.CallTrk.swap();   
    window.scrollTo(0,0);
  }
  componentDidUpdate(){
    window.CallTrk.swap();   
    window.scrollTo(0,0);
  }
  globalstylefun = () => {
    const globalstyle = window.adminStyle;
      if (globalstyle) {
        $('h2, .sectiontitle-headline').css({ "color": globalstyle.global_components_headline, "font-family": globalstyle.h2_fonts});
        $('h1').css({ "color": globalstyle.global_components_headline_h1, "font-family": globalstyle.h1_fonts });
        $('p').css({ "color": globalstyle.global_paragraph_text, "font-family": globalstyle.paragraph_fonts });
        $('.sub-title, .sectiontitle-eyebrow_text, .blog_card_category, .brand_heading').css("color", globalstyle.eyebrow_text_color);
        $('.btn-color').css("color", globalstyle.button_text_color);
        $('.btn-color').css({ "background-color": globalstyle.button_outlinebackground_color, "border": '1px solid' + globalstyle.button_outlinebackground_color });
        $('.cta_label_color').css({ "color": globalstyle.inline_linkscta, 'border-bottom': '2px solid' + globalstyle.inline_linkscta });
        $(".btn-color").mouseover(function () {
          $(this).css({ "background-color": globalstyle.button_hover, "border": '1px solid' + globalstyle.button_hover });
        }).mouseout(function () {
          $(this).css({ "background-color": globalstyle.button_outlinebackground_color, "border": '1px solid' + globalstyle.button_outlinebackground_color });
        });
      } 
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname != nextProps.location.pathname) {
      this.props = nextProps;
      this.setState({
        success: false
      })
      this.fetch_data();
    }
  }
  render() {
    var success = this.state.success;
    var message = this.state.message;
    var blog_component = this.state.blog_component.blog_data;
    var toShow;
    var window_width = window.screen.width;
    var current_url = window.location.href.split("/");
    var slug_value = current_url[current_url.length - 2];
    if (blog_component && blog_component.page_title) {
      var page_title = blog_component.page_title;
    }
    if (blog_component && blog_component.page_desc) {
      var page_desc = blog_component.page_desc;
    }
    if (blog_component && blog_component.banner_image) {
      var blog_image = blog_component.banner_image;
      var blog_image_width = blog_component.image_sizes[0];
      var blog_image_height = blog_component.image_sizes[1];
    }

    const meta = {
      title: page_title,
      description: page_desc,
      canonical: window.location.href,
      meta: {
        property: {
          'og:locale': 'en_US',
          'og:type': 'article',
          'og:title': page_title,
          'og:description': page_desc,
          'og:url': window.location.href,
          'og:site_name': 'Pecan Ridge',
          'og:image': blog_image,
          'og:image:width': blog_image_width,
          'og:image:height': blog_image_height,

          'twitter:card': 'summary',
          'twitter:description': page_desc,
          'twitter:title': page_title,
          'twitter:image': blog_image
        }
      }
    };
    return (  
      <>
      <DocumentMeta {...meta} />
      {
      success === true ? [
        <div className='blog-content-container blogs-section'>
          <div className="category_list">
            {this.state.blog_component.categories.map((item, i) => {
              const split_arr = item.name.replace("&amp;", "&").split(' & ');
              return <div 
              className="cat_list_item" 
              key={i} 
              onClick={() => window.location.href = '/blog/?category=' + item.slug}>
              {split_arr[0]} {split_arr[1] && [<br/>,`& ${split_arr[1]}`]}
              </div>
            })
            }
          </div>
          {blog_component.banner_image ? (
            <div className='blog-cover-detail-content'>
              <div className="blog-cover-content">
                {blog_component.banner_image &&
                  <div className="blog-cover-img divide-equal">
                    <img src={blog_component.banner_image} title={blog_component.title} alt={blog_component.title} />
                  </div>
                }
                <div className="blog-cover-info divide-equal">
                  <div className="header-section">
                    <div className="heading-section">
                      {blog_component.categoryname[0].name ? (

                        <p id="sub-eyebrowtext" className="sub-title">{blog_component.categoryname[0].name.replace("&amp;", "&")}</p>
                      ) : ('')}
                      {blog_component.title &&
                        <h2 className="main-title">
                          <span>{blog_component.title}</span>
                        </h2>
                      }
                    </div>
                    {(window_width > 991) ? (
                      blog_component.content ? (
                        toShow = blog_component.content.substring(0, 150) + "...",
                        <p className="title-info" dangerouslySetInnerHTML={{ __html: toShow.replace(/(?:\r\n|\r|\n)/g, '') }}></p>
                      ) : ('')
                    ) : ('')}
                    {(window_width > 991) ? (
                      blog_component.post_author ? (
                        <div className="author_name_date">By: {blog_component.post_author} {blog_component.post_date}</div>
                      ) : ('')
                    ) : ('')}
                    {(window_width > 991) ? (
                      <div className="social_icons">
                        <a className="facebook" href={"http://www.facebook.com/sharer.php?u=" + window.location.href + "&t=" + blog_component.title} title="Share this post on Facebook!" target="_blank" ><span class="icon-head -facebook"> </span><i class="fa fa-facebook-square" aria-hidden="true"></i></a>
                        <a className="twitter" href={"http://twitter.com/intent/tweet?text=" + blog_component.title + "&url=" + window.location.href} title="Share this post on Twitter!" target="_blank"><span class="icon-head -twitter"> </span><i class="fa fa-twitter" aria-hidden="true"></i></a>
                      </div>
                    ) : ('')}
                  </div>
                </div>
              </div>
              {blog_component.content ? (
                <div className='blog-content'>
                  <p dangerouslySetInnerHTML={{ __html: blog_component.content.replace(/(?:\r\n|\r|\n)/g, '') }}></p>
                  {(window_width <= 991) ? (
                    blog_component.post_author ? (
                      <div className="author_name_date">By: {blog_component.post_author} {blog_component.post_date}</div>
                    ) : ('')
                  ) : ('')}
                  <h6 className="social_icons">
                    <a className="facebook" href={"http://www.facebook.com/sharer.php?u=" + window.location.href + "&t=" + blog_component.title} title="Share this post on Facebook!" target="_blank" ><span class="icon-head -facebook"> </span><i class="fa fa-facebook-square" aria-hidden="true"></i></a>
                    <a className="twitter" href={"http://twitter.com/intent/tweet?text=" + blog_component.title + "&url=" + window.location.href} title="Share this post on Twitter!" target="_blank"><span class="icon-head -twitter"> </span><i class="fa fa-twitter" aria-hidden="true"></i></a>
                  </h6>

                </div>
              ) : ('')}
            </div>
          ) : ('')}

          {blog_component.result.length > 0 && <div className="card_heading_content">
            <h4 className="brand_heading">Pecan Ridge Blog</h4>
            <h2 className="latest_post_heading">More to Explore</h2>
          </div>}
          <div className="blog_card_container">
            {blog_component.result.map(item => (
              <div className="blog_card">
                {item.post_thumbnail_url ? (
                  <Link to={`/blog/${item.post_name}`}>
                    <div className="blog_card_img">
                      <img src={item.post_thumbnail_url} title={item.post_title} alt={item.post_title} />
                    </div>
                  </Link>
                ) : ('')}
                <div className="blog_card_content">
                  {item.category[0].name ? (
                    <div className="blog_card_category">
                      {item.category[0].name}
                    </div>
                  ) : ('')}
                  {item.post_title ? (
                    <div className="blog_card_content_caption">
                      <h4>{item.post_title}</h4>
                    </div>
                  ) : ('')}
                  {item.post_content ? (
                    toShow = item.post_content.substring(0, 150) + "...",
                    <div className="blog_card_content_para">
                      <div dangerouslySetInnerHTML={{ __html: toShow.replace(/(?:\r\n|\r|\n)/g, '') }} />
                    </div>
                  ) : ('')}
                  {item.post_name ? (
                    <div className="read_more_btn">
                      <Link to={`/blog/${item.post_name}`} className="read_more">Read More</Link>
                    </div>
                  ) : ('')}
                </div>
              </div>
            ))}
          </div>
        </div>
      ] : [message != 'Not Completed' ?  <TPHLoader/>  :  <Redirect to={<NoMatch/>} /> ]
    } 
        
      </>
     );
  }
}

export default BlogPage; 