import React from 'react';
import './GridSection.scss';
import HeadingSection from './HeadingSection';
class GridSection extends React.Component {

  render() {
    var grid_data = this.props.grid_data;
    return (

      grid_data.focus_box_content.length > 0 ? [
        <div className="container">
          <div className="grid-section">
            <div className="row">
              {grid_data.focus_box_content.map(items => (
                items.acf_fc_layout === "image" ? (
                  items.image.url ? (
                    <div className="col-lg-4 col-sm-6 grid-img">
                      <div>
                        <img src={items.image.url} title={items.image.title} alt={items.image.title} className="grid-icon" />
                      </div>
                    </div>
                  ) : ('')
                ) : (
                    items.headline || items.description ? (
                      <div className="col-lg-4 col-sm-6 grid-info">
                        <div className="grid-section-content">
                          <HeadingSection
                            title_part_1={items.headline}
                            title_part_2={items.headline_part_2}
                            headline_part_1_color={items.headline_part_1_color}
                            headline_part_2_color={items.headline_part_2_color} 
                          />
                          {items.description ? (
                            <p className="title-info" dangerouslySetInnerHTML={{ __html: items.description.replace(/(?:\r\n|\r|\n)/g, '') }}></p>
                          ) : ('')}
                          {items.cta_label && items.cta_link ? (
                            items.open_in_new_tab?
                            <a href={items.cta_link}  className="learn-more cta_label_color" target="_blank" title={items.cta_label}>{items.cta_label}</a>
                          : <a href={items.cta_link}  className="learn-more cta_label_color"  title={items.cta_label}>{items.cta_label}</a>) : ('')}
                        </div>
                      </div>
                    ) : ('')
                  )
              ))}
            </div>
          </div>
        </div>
      ] : ['']
    );
  }
}

export default GridSection; 