import React from 'react';
import './Residence.scss';
import Carousel from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from 'jquery';

class ResidenceScreen2 extends React.Component {
    constructor() {
        super()

        this.state = {
            nav1: null,
            nav2: null,
            nav3: null
        }
    }
    componentDidMount() {
      this.setState({
          nav1: this.slider1,
          nav2: this.slider2,
          nav3: this.slider3
      })
      $('.residence-header').removeClass('bdx-added');
    }
    render() {
        const slider1Settings = {
            asNavFor: this.state.nav2,
            ref: slider => (this.slider1 = slider),
            dots: true,
            infinite:false
        }
        const slider2Settings = {
            asNavFor: this.state.nav3,
            ref: slider => (this.slider2 = slider),
            arrows: false,
            dots: false,
            infinite:false,
            draggable: false,
            focusOnSelect: true
        }
        const slider3Settings = {
            asNavFor: this.state.nav1,
            ref: slider => (this.slider3 = slider),
            arrows: false,
            dots: false,
            infinite:false,
            draggable: false,
            fade: true,
            speed: 600
        }
        var residence_component = this.props.residence_component.exterior;
        return (
           
            <div className="residence-details" id="screen-2">
                <div className="residence-image"> 
                {residence_component.elevation_images.length > 0 ? [
                    residence_component.elevation_images.length === 1? (
                        <div className="image-div">
                            <img src={residence_component.elevation_images[0].image_url} alt="Gallery Image" title="Gallery Image" />
                        </div>
                    ):(
                        <div className="slider-wrapper">
                            <Carousel  {...slider1Settings} >
                                {residence_component.elevation_images.map(items => ( 
                                    <div>
                                        <img src={items.image_url} alt="Gallery Image" title="Gallery Image" />
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    )
                ]:['']}
                </div>
                <div className="residence-content">
                    <h1 className="plan-name">Exterior Choices</h1>
                    {residence_component.exterior_subtext ? (
                        <div className="plan-description">
                        <p>{residence_component.exterior_subtext }</p>
                    </div>
                    ):('')}
                
                    {residence_component.elevation_images.length > 0 ? [
                    <div className="plan-btns">
                        <div className="thumbnail-slider-wrap">
                            <Carousel  {...slider2Settings} >
                                {residence_component.elevation_images.map(items => ( 
                                    <div className="residence-btns">
                                        <p className="plan-btn" title={items.image_title}>{items.image_title}</p>
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    </div>,
                    <div className="btn-description">
                        <Carousel  {...slider3Settings} >
                            {residence_component.elevation_images.map(items => (
                            <div>
                                <div className="btn-name">{items.image_title}</div>
                                <p dangerouslySetInnerHTML={{__html:items.image_caption.replace(/(?:\r\n|\r|\n)/g, '')}}></p>
                            </div>
                            ))}
                        </Carousel>
                    </div>
                    ]:[]}
                </div>
            </div>
        );
    }
}
export default ResidenceScreen2; 
