import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from 'js-cookie';

const LogoutButton = () => {
  const { logout, isAuthenticated, user } = useAuth0();

  function  logout_user(){
    Cookies.set('user_account_layer',false,{ expires: 1 })
    logout({ returnTo: window.location.origin })
  }

  return ( 
    isAuthenticated ? [
      <div className="logout_btn">
        <h4>{user.email}</h4>
        <a href="javascript:void(0)" onClick={() => logout_user()}>Sign Out</a>
      </div>,
      <div className="mobile_logout_btn">
        <a href="javascript:void(0)" onClick={() => logout_user()}>Sign Out</a>
      </div>
    ]:['']
  );
};

export default LogoutButton;