import React from 'react';
import SectionTitle from './SectionTitle';
import {Accordion,Card,Button} from 'react-bootstrap';


class FaqSection extends React.Component {  
 
  render() {    
     var community_details = this.props.community_details.faq_info.faq_info ; 
     return ( 
            community_details.faq_questions !== null ? (
                community_details.faq_questions.length > 0 ? (
                    <div className="faq-section">  
                        <SectionTitle headline='Frequently Asked Questions' subtext={community_details.faq_heading} />
                        {community_details.faq_description  ? (
                            <p className="faq-section-subtext  no-mr" dangerouslySetInnerHTML={{__html:community_details.faq_description.replace(/(?:\r\n|\r|\n)/g, '')}}></p>
                        ):('')}
                        <Accordion defaultActiveKey="0item">
                        {community_details.faq_questions.map((item,key) => (
                            <Card>
                                <Accordion.Collapse eventKey={key+"item"}>
                                    <Card.Body>
                                    <div class="faq-panel">
                                        <div class="faq-description" dangerouslySetInnerHTML={{__html:item.post_content.replace(/(?:\r\n|\r|\n)/g, '')}}></div>
                                    </div>  
                                    </Card.Body>
                                </Accordion.Collapse>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey={key+"item"}>
                                        <div class="ques-heading">
                                        <p class="faq-question">{item.post_title}</p>
                                        <i class="fa fa-arrow-circle-o-down"></i>
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                            </Card>
                        ))}
                        </Accordion>  
                    </div>
                ):('')
            ):('')
          
        );  
    }  
}  

export default FaqSection; 