import React from 'react';
import './SubNavbar.scss';
import SidebarForm from './SidebarForm';
import SidebarSignUp from './SidebarSignUp.jsx';
import { Modal } from 'react-bootstrap';
import $ from 'jquery';

import phoneImg from '../../assets/images/phone.svg';
import mapImg from '../../assets/images/map.svg';
import mailImg from '../../assets/images/mail.svg';
import tourImg from '../../assets/images/tour.svg';


class UtilityNav extends React.Component {
    constructor() {
        super();
        this.state = {
            show: null
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.load_sfdc_chat = this.load_sfdc_chat.bind(this);
    }

    handleClose() {
        this.setState({ show: null });
    }

    handleShow(id) {
        this.setState({ show: id });
    }
    componentDidMount() {
        $(".utility-agent-image, .mobile-pulse").on('click', function () {
            $(".sidebar-form").show();
        });
        $(".mobile-close").on('click', function () {
            $(".sidebar-form").hide();
        });
        if ($(".sidebar-form").length > 0) {
            $(document).on('click touchstart', function (e) {
                var class_name = e.target.className;
                if (class_name.indexOf("mobile-pulse") == -1 && class_name.indexOf("utility-agent-image") == -1) {
                    var classList = e.target;
                    if ($(classList).closest(".sidebar-form").length <= 0) {
                        $(".sidebar-form").not(this).each(function () {
                            if ($(this).css('display') == 'block') {
                                $(this).hide();
                            }
                        });
                    }
                }
            })
        }

        // if(this.props.sfdc_chat.sfdc_community_id != ''){
        //     window.SHOW_CHAT = true;
        //     if(window.SHOW_CHAT){
        //         if(window.JQUERY(".embeddedServiceSidebar").length > 0){
        //             window.JQUERY(".embeddedServiceSidebar").show();
        //         }
        //         else{
        //             window.JQUERY(".embeddedServiceHelpButton").show();
        //         }            
        //         this.load_sfdc_chat();
        //    }
        // }
        if (window.location.href.indexOf("#interest-form") > -1) {
            document.getElementById("interest_click_btn").click();
        }
    }


    load_sfdc_chat() {
        var that = this;
        console.log('Chat Details');
        console.log(that.props.sfdc_chat);
        var initESW = function (gslbBaseURL) {
            window.embedded_svc.settings.displayHelpButton = true; //Or false
            window.embedded_svc.settings.language = ''; //For example, enter 'en' or 'en-US'

            window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
            window.embedded_svc.settings.entryFeature = 'LiveAgent';

            console.log('Chat Properties');
            console.log(window.embedded_svc);

            window.embedded_svc.settings.iframeURL = 'https://service.force.com/embeddedservice/5.0/esw.html?parent=' + window.location.href;

            window.embedded_svc.settings.extraPrechatFormDetails = [
                { "label": "First Name", "transcriptFields": ["ContactFirstName__c"] },
                { "label": "Last Name", "transcriptFields": ["ContactLastName__c"] },
                { "label": "Email", "transcriptFields": ["ContactEmail__c"] },
                { "value": that.props.sfdc_chat.sfdc_community_id, "transcriptFields": ["CommunityCRMId__c"] },
                { "value": window.location.href, "transcriptFields": ["WebsiteURL__c"] },
                { "label": "Question", "transcriptFields": ["ContactQuestion__c"] },
                { "label": "Preferred Phone", "transcriptFields": ["ContactPhone__c"] },
                { "value": that.props.sfdc_chat.sfdc_deployment_id, "transcriptFields": ["LiveChatDeployment"] },
                { "value": that.props.sfdc_chat.sfdc_button_id, "transcriptFields": ["LiveChatButton"] },
                { "value": that.props.sfdc_chat.sfdc_eswliveagent_devname, "transcriptFields": ["ESWLiveAgentDevName__c"] },
                { "value": that.props.sfdc_chat.sfdc_embedded_chat_group, "transcriptFields": ["EmbeddedServiceDepName__c"] }
            ];

            //Added below code for not creating new Contact in the system if no match found for contact in Salesforce 
            window.embedded_svc.settings.extraPrechatInfo = [
                {
                    "entityFieldMaps": [
                        { "doCreate": false, "doFind": true, "fieldName": "LastName", "isExactMatch": false, "label": "Last Name" },
                        { "doCreate": false, "doFind": true, "fieldName": "FirstName", "isExactMatch": false, "label": "First Name" },
                        { "doCreate": false, "doFind": true, "fieldName": "Email", "isExactMatch": false, "label": "Email" }],
                    "entityName": "Contact", "showOnCreate": false
                }
            ];

            window.embedded_svc.init(
                'https://tripointegroup.my.salesforce.com',
                'https://tripointegroup.secure.force.com',
                gslbBaseURL,
                '00Dj0000001s1Gn',
                that.props.sfdc_chat.sfdc_embedded_chat_group,
                {
                    baseLiveAgentContentURL: 'https://c.la3-c1-ph2.salesforceliveagent.com/content',
                    deploymentId: that.props.sfdc_chat.sfdc_deployment_id,
                    buttonId: that.props.sfdc_chat.sfdc_button_id,
                    baseLiveAgentURL: 'https://d.la3-c1-ph2.salesforceliveagent.com/chat',
                    eswLiveAgentDevName: that.props.sfdc_chat.sfdc_eswliveagent_devname,
                    isOfflineSupportEnabled: false
                }
            );
        };

        if (!window.embedded_svc) {
            var s = document.createElement('script');
            s.setAttribute('src', 'https://tripointegroup.my.salesforce.com/embeddedservice/5.0/esw.min.js');
            s.onload = function () {
                initESW(null);
            };
            document.body.appendChild(s);
        } else {
            initESW('https://service.force.com');
        }

    }
    calltoaction_data_layer_push(param) {
        this.setState({ value: param })
        // if (param && param === 'clickToCall') {
        //     window.dataLayer.push({ 'event': 'clickToCall' });
        // }
    }
    direction_data_layer_push(param) {
        this.setState({ value: param })
        // if (param && param === 'drivingDirections') {
        //     window.dataLayer.push({ 'event': 'drivingDirections' });
        // }
    }

    render() {
        var agent_info = this.props.agent_info;
        var community_data = this.props.community_component;
        var posttype = this.props.posttype;
        return (
            <div className="utility-nav">
                <div className="utility-nav-info divide-utility">
                    {agent_info.agents_contact_phone ? (
                        <a href={"tel:" + agent_info.agents_contact_phone} className="phone-call" onClick={() => this.calltoaction_data_layer_push('clickToCall')}>
                            <img src={phoneImg} alt="phone" title="phone" className="utility-call" />
                            <span>Call</span>
                        </a>
                    ) : ('')}
                    {community_data.physical_address.map_directions_link || community_data.sales_office_address.custom_direction_link ? (
                        community_data.physical_address.map_directions_link ? (
                            <a href={community_data.physical_address.map_directions_link} className="directions" target="_blank" onClick={() => this.direction_data_layer_push('drivingDirections')}>
                                <img src={mapImg} alt="map" title="map" className="utility-map" />
                                <span>Map</span>
                            </a>
                        ) : (
                                <a href={community_data.sales_office_address.custom_direction_link} className="directions" target="_blank" onClick={() => this.direction_data_layer_push('drivingDirections')}>
                                    <img src={mapImg} alt="map" title="map" className="utility-map" />
                                    <span>Map</span>
                                </a>
                            )
                    ) : ('')}
                    <a href="javascript:void(0)" className="stay-informed-btn" id="interest_click_btn" onClick={() => this.handleShow("subscribe_form")}>
                        <img src={mailImg} alt="subscribe" title="subscribe" className="utility-subscribe" />
                        <span>Subscribe</span>
                    </a>

                    {community_data.tour_details.show_tour_btn ? [
                        posttype === 'qmi' ? (
                            community_data.mobile_button_text && community_data.qmi_status !== 'Coming Soon' ? (
                                <a href="javascript:void(0)" className="tour_btn" data-fancybox-trigger="tour_form" id="tour_click_btn">
                                    <img src={tourImg} alt="Tour" title="Tour" className="utility-tour" />
                                    <span>{community_data.mobile_button_text}</span>
                                </a>
                            ) : ('')
                        ) : (
                                community_data.community_status !== 'Coming Soon' ? (
                                    <a href="javascript:void(0)" className="tour_btn" data-fancybox-trigger="tour_form" id="tour_click_btn" >
                                        <img src={tourImg} alt="Tour" title="Tour" className="utility-tour" />
                                        <span>Tour</span>
                                    </a>
                                ) : ('')
                            )
                    ] : ['']}

                </div>
                <Modal className="Estimate_modal Request_quote" show={this.state.show === "subscribe_form"} onHide={this.handleClose} >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        {posttype === 'qmi' ? (
                            <SidebarSignUp sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={community_data.neigh_title} division_name={community_data.division_name} />
                        ) : (
                                <SidebarSignUp sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={community_data.title} division_name={community_data.division_name} />
                            )}
                    </Modal.Body>
                </Modal>
                {agent_info.agent_thumbnail ? [
                    <div className="utility-specialist-img divide-utility">
                        <img src={agent_info.agent_thumbnail} alt="Agent" title="Agent" className='utility-agent-image' />
                        <div className="pulse mobile-pulse"></div>
                    </div>,
                    posttype === 'qmi' ? (
                        <SidebarForm agent_info={community_data.agent_info} post_status={community_data.qmi_status} schedule_button_text={community_data.schedule_button_text} tour_btn={community_data.tour_details} posttype='qmi' community_data={community_data} />
                    ) : (
                            <SidebarForm agent_info={community_data.agent_info} post_status={community_data.community_status} tour_btn={community_data.tour_details} community_data={community_data} />
                        )
                ] : ['']}
            </div>
        );
    }
}

export default UtilityNav; 
