import axios from 'axios';
import React from 'react';
import SubNavbar from '../../Components/SubNavbar';
import UtilityNav from '../../Components/UtilityNav';
import Direction from '../../Components/Direction';
import DirectionMapBlock from '../../Components/DirectionMapBlock';
import { Redirect } from "react-router-dom";
import { apiConfig } from '../../Components/Helper.jsx';
import NoMatch from './NoMatch';
import NhsDesktop from '../../Components/NhsDesktop';
import TPHLoader from '../../Components/TPHLoader.jsx';
import DocumentMeta from 'react-document-meta';
import $ from 'jquery';
class DirectionPage extends React.Component {

	state = {
		direction_component: '',
		success: false,
	}
	/*Local Business*/
	local_business_structured_data(direction_details) {
		if (direction_details) {

			if (direction_details.agent_info.agents_contact_phone) {
				var telephone = direction_details.agent_info.agents_contact_phone;
			}
			if (direction_details.min_price) {
				var priceRange = direction_details.min_price;
			}
			if (direction_details.physical_address.physical_address_heading) {
				var addressLocality = direction_details.physical_address.physical_address_heading;
			}
			if (direction_details.streetAddress) {
				var streetAddress = direction_details.streetAddress;
			}
			if (direction_details.postalCode) {
				var postalCode = direction_details.postalCode;
			}
			if (direction_details.latitude) {
				var latitude = direction_details.latitude;
			}
			if (direction_details.longitude) {
				var longitude = direction_details.longitude;
			}
			if (direction_details.state_abb) {
				var state_abb = direction_details.state_abb.toUpperCase();
			}
			if (direction_details.site_logo) {
				var site_logo = direction_details.site_logo;
			}
		}
		const script = document.createElement("script");
		// Structure Data script start 
		script.type = "application/ld+json";
		script.innerHTML = `{
		"@context": "https://schema.org",
		"@type": "LocalBusiness",
		"name": "Pecan Ridge",
		"image": "${site_logo}",
		"@id": "",
		"url": "${window.location.href}",
		"telephone": "${telephone}",
		"priceRange": "${priceRange}",
		"address": {
		"@type": "PostalAddress",
		"streetAddress": "${streetAddress}",
		"addressLocality": "${addressLocality}",
		"addressRegion": "${state_abb}",
		"postalCode": "${postalCode}",
		"addressCountry": "US"
		},
		"geo": {
		"@type": "GeoCoordinates",
		"latitude": "${latitude}",
		"longitude": "${longitude}"
		}  
		}`
		//  Structure Data script end 
		document.head.appendChild(script);
	}
	fetch_data() {
		axios.get(apiConfig().API_URL + '/common_info/' + this.props.match.params.slug + '/direction')
			.then(response => {

				this.setState({
					direction_component: response.data.data,
					success: response.data.success,
				}, () => {
					if (this.state.success == true) {
					  window.adminStyle = this.state.direction_component && this.state.direction_component.direction_details.global_style;
					  this.globalstylefun();
					} }
				)
				console.log(response);
			})
	}

	componentDidMount() {
		this.fetch_data();
		window.globalstylefun = this.globalstylefun;
		document.addEventListener("click", function(e) {
		  window.globalstylefun();
		});
		window.CallTrk.swap();
		window.JQUERY('.top-scroll').addClass('dynamic');
	}
	globalstylefun = () => {
		const globalstyle = window.adminStyle;
		  if (globalstyle) {
			$('h2, .sectiontitle-headline').css({ "color": globalstyle.global_components_headline, "font-family": globalstyle.h2_fonts});
			$('h1').css({ "color": globalstyle.global_components_headline_h1, "font-family": globalstyle.h1_fonts });
			$('p').css({ "color": globalstyle.global_paragraph_text, "font-family": globalstyle.paragraph_fonts });
			$('.sub-title, .sectiontitle-eyebrow_text, .blog_card_category, .brand_heading').css("color", globalstyle.eyebrow_text_color);
			$('.btn-color').css("color", globalstyle.button_text_color);
			$('.btn-color').css({ "background-color": globalstyle.button_outlinebackground_color, "border": '1px solid' + globalstyle.button_outlinebackground_color });
			$('.cta_label_color').css({ "color": globalstyle.inline_linkscta, 'border-bottom': '2px solid' + globalstyle.inline_linkscta });
			$(".btn-color").mouseover(function () {
			  $(this).css({ "background-color": globalstyle.button_hover, "border": '1px solid' + globalstyle.button_hover });
			}).mouseout(function () {
			  $(this).css({ "background-color": globalstyle.button_outlinebackground_color, "border": '1px solid' + globalstyle.button_outlinebackground_color });
			});
		  } 
	  }
	componentDidUpdate() {
		window.CallTrk.swap();
	}

	render() {
		var window_width = window.screen.width;
		var success = this.state.success;
		var message = this.state.message;
		var direction_component = this.state.direction_component;
		var direction_details = direction_component.direction_details;
		var posttype = this.state.direction_component.post_type;
		var state_name = this.props.match.params.statename.toLowerCase();
		var submarket_name = this.props.match.params.submarket.toLowerCase();
		if (direction_details) {
			var dir_url = window.location.href;
			if (direction_details.title) {
				var title = direction_details.title;
			}
			if (direction_details.state) {
				var stateName = direction_details.state;
			}
			if (direction_details.city) {
				var cityname = direction_details.city;
			}
		}
		const meta = {
			title: direction_component.yoast_title,
			description: direction_component.yoast_desc,
			canonical: window.location.href
		  };
		if (direction_details) {
			this.local_business_structured_data(direction_details);
		}
		return (
			<>
			<DocumentMeta {...meta} />
			{
			success === true ? [
				state_name === direction_details.state_abb.toLowerCase() && submarket_name === direction_details.submarket.toLowerCase() ? (
					direction_details.show_direction === true ? [
						<div className="direction-main">
							<SubNavbar navbar_detail={direction_component} community_details={direction_details} posttype={posttype} />
							<Direction direction_details={direction_details} posttype={posttype} />
							<DirectionMapBlock direction_details={direction_details} />
							{(window_width < 767) ? (
								<UtilityNav sfdc_chat={direction_details.sfdc_chat_details} agent_info={direction_details.agent_info} community_component={direction_details} />
							) : (
									<NhsDesktop sfdc_chat={direction_details.sfdc_chat_details} agent_info={direction_details.agent_info} community_component={direction_details} />
								)}
						</div>
					] : [<Redirect to={direction_details.comm_permalink} />]
				) : (<Redirect to={<NoMatch />} />)
			] : [message != 'Not Completed' ?  <TPHLoader/>  :  <Redirect to={<NoMatch/>} /> ]
		}
		
			</>
		);
	}
}

export default DirectionPage; 
