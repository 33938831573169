import React from 'react';
import './Residence.scss';
import { withAuth0 } from "@auth0/auth0-react";
import $, { event } from 'jquery';

class Dreamweaver extends React.Component {

    constructor() {
        super();
        this.state ={
            token : '',
            dreamweaver_load : false,
          }
        this.load_dreamweaver = this.load_dreamweaver.bind(this);
    }

    getUserMetadata = async () => {   
        const accessToken = await this.props.auth0.getAccessTokenSilently();
        this.setState({ 
            token: accessToken != '' ? accessToken : '',
            dreamweaver_load: true 
        });
    };

    componentDidMount() {

        if(this.props.auth0.isAuthenticated == true){
            this.getUserMetadata();
        }
        else{
            this.setState({ 
                dreamweaver_load: true, 
            });
        }

        if( window.JQUERY('#bdx_dreamweaver_section').length > 0){
            window.JQUERY('.header_section').hide();
            window.JQUERY('.footer_section').hide();
            window.JQUERY('.top-scroll').hide();
            window.JQUERY('.top-scroll').removeClass('dynamic');
        }
        // this.load_dreamweaver();
        console.log(this.props.auth0)
    }

    load_dreamweaver(){
        var that = this;
        var payload = {
            container: "bdx_dreamweaver_section", // div container id(Clientneed to pass div id only)
            type:1, // if start from floorplan then we have to passtype =1, start from ISP then type will be 0, if start from visualizerthen type will be 2
            backtopageText: that.props.residence_component.common_detail.nbd_title,//Default text is Back to CommunityDetails Page, if we want to change then we can pass the any string tothis key
            planNumber: that.props.residence_component.dreamweaver_plan_id, //client will provide this to start withIFP in dreamweaveror
            accessToken: this.state.token,
            authDomain: process.env.Auth0_Domain,
            };
    
         window.MEDIALOADER.initDreamWeaver(payload, function (data) {
             
            console.log("callback:", data);
        });
        document.addEventListener('backToDetailPage', function (e)
        { 
            window.location.replace(window.location.origin+that.props.residence_component.common_detail.nbd_permalink);

        }, false);
        this.setState({ 
            dreamweaver_load: false, 
        });
    }

    componentDidUpdate(){
        if(this.state.dreamweaver_load == true){
            this.load_dreamweaver();
        }
    }

    render() {
        var residence_component = this.props.residence_component;
        return (
            <div className="dreamweaver_div bfx_plugin_div" id="bdx_dreamweaver_section">

            </div>
        );
    }
}
export default withAuth0(Dreamweaver); 
