import React from 'react';
import './PhotoGallery.scss';
import './GalleryModal.scss';
import HeadingSection from './HeadingSection';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Modal } from 'react-bootstrap';
import GalleryModal from './GalleryModal';
import $ from 'jquery';

class PhotoGallery extends React.Component {
    constructor() {
        super();

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
            show: null,
            dataid: 0,
            options: {
                margin: 56,
                stagePadding: 50,
                nav: true,
                loop: false,
                navText: [
                    '<div class="sliderArrow arrowLeft"></div>',
                    '<div class="sliderArrow arrowRight"></div>'
                ],
                autoplay: false,
                startPosition: 0,
                responsive: {
                    0: {
                        items: 1,
                        margin: 20,
                        stagePadding: 70
                    },
                    768: {
                        items: 3,
                        stagePadding: 50
                    },
                    1601: {
                        stagePadding: 70,
                        items: 3
                    }

                }
            }
        };
    }

    handleClose() {
        this.setState({ show: null });
    }

    handleShow(id, selected_index) {
        this.setState({ show: id });
        this.setState({ selected_index: selected_index });

    }
    componentDidMount() {
        //     $(".photo-section .owl-nav .owl-prev").click(function(){
        //         window.dataLayer.push({'event':'imageGalleryPrev'});
        //     })

        //     $(".photo-section .owl-nav .owl-next").click(function(){
        //         window.dataLayer.push({'event':'imageGalleryNext'});
        //    })
    }
    /*product*/
    product_structured_data(communityComponent, media_gallery) {
        if (communityComponent) {
            if (communityComponent.min_price) {
                var priceRange = communityComponent.min_price;
            }
            if (communityComponent.description) {
                var description = communityComponent.description.replace(/(<([^>]+)>)/ig, '');
            }
            if (communityComponent.title) {
                var title = communityComponent.title;
            }

        }
        if (media_gallery) {
            if (media_gallery.gallery_images.length >= 1 && media_gallery.gallery_images[0].image.url) {
                var image1 = media_gallery.gallery_images[0].image.url;
            }
            if (media_gallery.gallery_images.length >= 2 && media_gallery.gallery_images[1].image.url) {
                var image2 = media_gallery.gallery_images[1].image.url;
            }
            if (media_gallery.gallery_images.length >= 3 && media_gallery.gallery_images[2].image.url) {
                var image3 = media_gallery.gallery_images[2].image.url;
            }
        }
        const script = document.createElement("script");
        // Structure Data script start 
        script.type = "application/ld+json";
        script.innerHTML = `{
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": "${title}",
      "image": [
        "${image1}",
        "${image2}",
        "${image3}"
        ],
      "description": "${description}",
      "sku": "",
      "mpn": "",
      "brand": {
        "@type": "Thing",
        "name": "ACME"
      },
      "offers": {
        "@type": "Offer",
        "url": "${window.location.href}",
        "priceCurrency": "USD",
        "price": "${priceRange}",
        "priceValidUntil": "",
        "itemCondition": "https://schema.org/UsedCondition",
        "availability": "https://schema.org/InStock",
        "seller": {
          "@type": "Organization",
          "name": "Executive Objects"
        }
      }
    }`
        //  Structure Data script end 
        document.head.appendChild(script);

    }
    imageGalleryClick_data_layer_push(param) {
        this.setState({ value: param })
        if (param && param === 'imageGalleryClick') {
            window.dataLayer.push({ 'event': 'imageGalleryClick' });
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.show !== this.state.show && !this.state.show) {
            this.setState({ options: { ...this.state.options, startPosition: this.state.selected_index || 0 } });
        }

    }
    render() {
        var media_gallery = this.props.media_gallery;
        var communityComponent = this.props.community_details;
        if (communityComponent) {
            this.product_structured_data(communityComponent, media_gallery);
        }

        return (
            media_gallery.gallery_images.length > 0 ? (
                <div className="photo-section">
                    <div className="container">
                        <div className="headerSectionWrapper">
                            <HeadingSection
                                subtitle={media_gallery.sub_text}
                                title_part_1={media_gallery.headline}
                                title_part_2={media_gallery.headline_part_2}
                                headline_part_1_color={media_gallery.headline_part_1_color}
                                headline_part_2_color={media_gallery.headline_part_2_color}
                            />
                        </div>

                        {media_gallery.gallery_images.length > 0 ? [
                            <div className="photo-info">
                                <div className="photo-main-img">
                                    {media_gallery.gallery_images.length === 1 ? [
                                        media_gallery.gallery_images.image !== false ? (
                                            <div className="single-image">
                                                {media_gallery.virtual_tour === 1 ? [
                                                    <img src={media_gallery.gallery_images[0].image.url} title={"New homes in " + media_gallery.gallery_images[0].image.title} alt={media_gallery.gallery_images[0].image.title} onClick={() => this.imageGalleryClick_data_layer_push('imageGalleryClick')} />,
                                                    <p className="tour-img" onClick={() => this.handleShow("Virtual")}><img src={require('../../assets/images/virtual-tour.svg').default} title="icon" alt="icon" className="virtual-tour" />Start Tour</p>
                                                ] : [
                                                        <img src={media_gallery.gallery_images[0].image.url} onClick={() => { this.handleShow("Gallery", 0); this.imageGalleryClick_data_layer_push('imageGalleryClick') }} title={media_gallery.gallery_images[0].image.title} alt={media_gallery.gallery_images[0].image.title} />
                                                    ]}
                                            </div>
                                        ) : ('')
                                    ] : [
                                            <OwlCarousel {...this.state.options} className="owl-theme">
                                                {media_gallery.gallery_images.map((items, key) => (
                                                    items.image !== false ? (
                                                        <div>
                                                            {media_gallery.virtual_tour === 1 ? (
                                                                key === 0 ? [
                                                                    <img src={items.image.url} title={items.image.title} alt={"New homes in " + items.image.title} onClick={() => this.imageGalleryClick_data_layer_push('imageGalleryClick')} />,
                                                                    <p className="tour-img" onClick={() => this.handleShow("Virtual")}><img src={require('../../assets/images/virtual-tour.svg').default} title="icon" alt="icon" className="virtual-tour" />Start Tour</p>
                                                                ] : [
                                                                        <img src={items.image.url} onClick={() => { this.handleShow("Gallery", key); this.imageGalleryClick_data_layer_push('imageGalleryClick') }} title={items.image.title} alt={"New homes in " + items.image.title} />
                                                                    ]
                                                            ) : (
                                                                    <img src={items.image.url} onClick={() => { this.handleShow("Gallery", key); this.imageGalleryClick_data_layer_push('imageGalleryClick') }} title={items.image.title} alt={"New homes in " + items.image.title} />
                                                                )}
                                                        </div>
                                                    ) : ('')
                                                ))}
                                            </OwlCarousel>
                                        ]}
                                </div>
                            </div>,
                            <Modal show={this.state.show === "Gallery"} onHide={this.handleClose} className="photo_gallery_modal comm_gallery_modal newGalleryModal">
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <h2>{media_gallery.iframe_heading}</h2>
                                        <p>{media_gallery.iframe_subtext}</p>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <GalleryModal gallery={media_gallery} selected_index={this.state.selected_index} />
                                </Modal.Body>
                            </Modal>
                        ] : ['']}
                        {media_gallery.virtual_tour === 1 ? (
                            <Modal show={this.state.show === "Virtual"} onHide={this.handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <h2>{media_gallery.virtual_tour_title}</h2>
                                        <p>{media_gallery.virtual_tour_sub_title}</p>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {media_gallery.virtual_tour_url ? (
                                        <iframe src={media_gallery.virtual_tour_url} className="virtual_iframe" title="virtual iframe"></iframe>
                                    ) : ('')}
                                </Modal.Body>
                            </Modal>
                        ) : ('')}
                    </div>
                </div>
            ) : ('')
        );
    }
}

export default PhotoGallery; 