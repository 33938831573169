import React from 'react';
import axios from 'axios';
import SubNavbar from '../../Components/SubNavbar';
import CommBanner from '../../Components/CommBanner';
import UtilityNav from '../../Components/UtilityNav';
import NeighCard from '../../Components/NeighCard';
import GlobalField from '../../Components/GlobalField';
import PromotionBlock from '../../Components/PromotionBlock';
import TPHLoader from '../../Components/TPHLoader.jsx';
import FaqSection from '../../Components/FaqSection';
import {apiConfig} from '../../Components/Helper.jsx';
import NhsDesktop from '../../Components/NhsDesktop';
import { Redirect } from "react-router-dom";
import NoMatch from './NoMatch';
import PlanCard from '../../Components/PlanCard';
import QmiCard from '../../Components/QmiCard';
import LifestyleListing from '../../Components/LifestyleListing';
import $ from 'jquery';
import DocumentMeta from 'react-document-meta';

class Community extends React.Component {  
 
  state ={
    community_component : [],
    success : false,
    message: ''
  }
  constructor() {
    super();
  }
  /*Local Business*/
  local_business_structured_data(communityComponent){
    if(communityComponent) {

      if(communityComponent.agent_info.agents_contact_phone){
        var telephone = communityComponent.agent_info.agents_contact_phone;
      }
      if(communityComponent.min_price){
        var priceRange = communityComponent.min_price;
      }
      if(communityComponent.physical_address.physical_address_heading){
        var addressLocality = communityComponent.physical_address.physical_address_heading;
      }
      if(communityComponent.streetAddress){
        var streetAddress = communityComponent.streetAddress;
      }
      if(communityComponent.postalCode){
        var postalCode = communityComponent.postalCode;
      }
      if(communityComponent.latitude){
        var latitude = communityComponent.latitude;
      }
      if(communityComponent.longitude){
        var longitude = communityComponent.longitude;
      }
      if(communityComponent.state_abb){
        var state_abb = communityComponent.state_abb.toUpperCase();
      }
      if(communityComponent.site_logo){
        var site_logo = communityComponent.site_logo;
      }
    }
    const script = document.createElement("script");
    // Structure Data script start 
    script.type = "application/ld+json";
    script.innerHTML = `{
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      "name": "Pecan Ridge",
      "image": "${site_logo}",
      "@id": "",
      "url": "${window.location.href}",
      "telephone": "${telephone}",
      "priceRange": "${priceRange}",
      "address": {
      "@type": "PostalAddress",
      "streetAddress": "${streetAddress}",
      "addressLocality": "${addressLocality}",
      "addressRegion": "${state_abb}",
      "postalCode": "${postalCode}",
      "addressCountry": "US"
      },
      "geo": {
      "@type": "GeoCoordinates",
      "latitude": "${latitude}",
      "longitude": "${longitude}"
      }  
    }`
      //  Structure Data script end 
      document.head.appendChild(script);

  }
  fetch_data() {
    axios.get(apiConfig().API_URL+'/community_info/'+ this.props.slug)
    .then(response => {

      var title = response.data.data.community_details.title;

      this.setState({
        community_component : response.data.data,
        success: response.data.success,
        message: response.data.message
      }, () => {
        if (this.state.success == true) {
          window.adminStyle = this.state.community_component && this.state.community_component.community_details.global_style;
          this.globalstylefun();
        } }
      )
      console.log(response);
    })
  }
  componentDidMount(){
    this.fetch_data();
    window.globalstylefun = this.globalstylefun;
    document.addEventListener("click", function(e) {
      window.globalstylefun();
    });
    window.CallTrk.swap();   
    window.scrollTo(0,0);
    window.JQUERY('.top-scroll').addClass('dynamic');
    
  }
  globalstylefun = () => {
    const globalstyle = window.adminStyle;
      if (globalstyle) {
        $('h2, .sectiontitle-headline').css({ "color": globalstyle.global_components_headline, "font-family": globalstyle.h2_fonts});
        $('h1').css({ "color": globalstyle.global_components_headline_h1, "font-family": globalstyle.h1_fonts });
        $('p').css({ "color": globalstyle.global_paragraph_text, "font-family": globalstyle.paragraph_fonts });
        $('.sub-title, .sectiontitle-eyebrow_text, .blog_card_category, .brand_heading').css("color", globalstyle.eyebrow_text_color);
        $('.btn-color').css("color", globalstyle.button_text_color);
        $('.btn-color').css({ "background-color": globalstyle.button_outlinebackground_color, "border": '1px solid' + globalstyle.button_outlinebackground_color });
        $('.cta_label_color').css({ "color": globalstyle.inline_linkscta, 'border-bottom': '2px solid' + globalstyle.inline_linkscta });
        $(".btn-color").mouseover(function () {
          $(this).css({ "background-color": globalstyle.button_hover, "border": '1px solid' + globalstyle.button_hover });
        }).mouseout(function () {
          $(this).css({ "background-color": globalstyle.button_outlinebackground_color, "border": '1px solid' + globalstyle.button_outlinebackground_color });
        });
      } 
  }
  componentDidUpdate(){
    if(this.state.success == true){
      // window.dataLayer.push({
      //     'event': 'communityId',
      //     'divisionName': this.state.community_component.community_details.title ? this.state.community_component.community_details.title : "",
      //     'e1CommunityId': this.state.community_component.community_details.e1_community_id ? this.state.community_component.community_details.e1_community_id : "",
      //     'salesforceId': this.state.community_component.community_details.communityOfInterest ? this.state.community_component.community_details.communityOfInterest : ""
          
      // });
    }
    window.CallTrk.swap();   
    window.scrollTo(0,0);
  }
  componentWillReceiveProps() {
    this.fetch_data();
    window.scrollTo(0, 0);
    window.JQUERY('.top-scroll').addClass('dynamic');
  }
  render() {     
   
    var window_width = window.screen.width;
    var success = this.state.success; 
    var message = this.state.message; 
    var community_component = this.state.community_component;
    var state_name = this.props.state.toLowerCase();
    var submarket_name = this.props.submarket.toLowerCase();
    var count_global_class = '';
    var communityComponent = this.state.community_component.community_details;
    if(communityComponent) {
      if(communityComponent.global_component > 0 && communityComponent.global_component < 4){
          count_global_class = '';
      }else{
          count_global_class = 'community';
      }
    }
    const meta = {
      title: community_component.yoast_title,
      description: community_component.yoast_desc,
      canonical: window.location.href
    };
    if(communityComponent){
      this.local_business_structured_data(communityComponent);
    }
     return (
        <>
        <DocumentMeta {...meta} />
          <div className="Community-main"> 
            {success === true ? [
              state_name === community_component.community_details.state_abb.toLowerCase() && submarket_name === community_component.community_details.submarket.toLowerCase() && community_component.community_details.community_status !== "Archive" ? [

              <SubNavbar navbar_detail={community_component} community_details={community_component.community_details}/>,

              community_component.community_details ? (
                <CommBanner community_details={community_component.community_details}/>
              ):(''),

              community_component.neighborhood ? (
                  <NeighCard heading_data={community_component.community_details} neigh_detail={community_component.neighborhood}/>
              ):(''),

              community_component.community_details.global_component > 0 ? (
                  <GlobalField slug={this.props.slug} slice_start={0} slice_end={4} global_data={community_component.community_details.component} posttype={count_global_class}/>
              ):(''),
              // community_component.residence  ? (
              //   <PlanCard plan_detail={community_component.residence} />
              // ):(''),
              community_component.qmi ? (
                <QmiCard Qmi_detail={community_component.qmi} posttype='mpc'/>
              ):(''),
              community_component.community_details.promotion_info ? (
                  <PromotionBlock promo_data={community_component.community_details.promotion_info} />
              ):(''),
              community_component.community_details.lifestyle_data ? (
                <LifestyleListing lifestyle_data={community_component.community_details.lifestyle_data} />
              ):(''),
              // community_component.community_details.global_component >=4 ? (
              //     <GlobalField slug={this.props.slug} slice_start={4} community_details={community_component.community_details.component}/>
              // ):(''),
              <FaqSection community_details={community_component.community_details}/>,
              (window_width < 767) ? (  
                <UtilityNav sfdc_chat={community_component.community_details.sfdc_chat_details} agent_info={community_component.community_details.agent_info} community_component={community_component.community_details}/>
              ):(
                <NhsDesktop sfdc_chat={community_component.community_details.sfdc_chat_details} agent_info={community_component.community_details.agent_info} community_component={community_component.community_details}/>
              )
            ]:[ '' ]
          ] : [message != 'Not Completed' ?  <TPHLoader/>  :  <Redirect to={<NoMatch/>} /> ]}
          </div>  
        </>
      );  
  }  
}  

export default Community; 
