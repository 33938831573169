import React from 'react';
import './Residence.scss';
import $ from 'jquery'
import ScheduleTourForm from './ScheduleTourForm.jsx';
import '../../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.css'
import SidebarSignUp from './SidebarSignUp.jsx';
import { Modal } from 'react-bootstrap';
import MortgageSection from './MortgageSection';

import defaultImg from '../../assets/images/TPH-default-image2.gif';

class ResidenceScreen5 extends React.Component {
    constructor() {
        super();
        this.state = {
            show: null
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        this.setState({ show: null });
    }

    handleShow(id) {
        this.setState({ show: id });
        // if(id && id==='Estimate') {
        //     window.dataLayer.push({'event':'mortgageCalculator'});
        // }
    }
    componentDidMount() {
        $('.residence-header').removeClass('bdx-added');
    }
    render() {
        var residence_component = this.props.residence_component;
        console.log(residence_component,defaultImg);
        return (
            <div className="residence-details" id="screen-5">
                <div className="residence-image"> 
                    <div className="image-column"> 
                    { residence_component.summary.summary_image ? (
                        <img src={residence_component.summary.summary_image.url} alt={residence_component.summary.summary_image.title} />
                    ):(
                        <img src={defaultImg} alt="defaultImg" title="defaultImg" />
                    ) }
                    </div>
                    <div className="detail-column"> 
                    {residence_component.common_detail.neigh_status != 'Temporarily Sold Out' ? [
                        residence_component.common_detail.post_status == 'Temporarily Sold Out' || residence_component.common_detail.post_status == 'Coming Soon' ? [
                            residence_component.summary.join_the_list_cta && (residence_component.summary.join_the_list_heading || residence_component.summary.join_the_list_description)  ? (
                                <div className="detail-content">
                                    <div className="detail-text">
                                        <div className="detail-title">{residence_component.summary.join_the_list_heading}</div>
                                        <p className="detail-description">{residence_component.summary.join_the_list_description}</p>
                                    </div>
                                    <div className="residence-btn">
                                    <a href="javascript:void(0)" className="resisdence-save-btn btn-color" title="Join The Interest List" onClick={() => this.handleShow("Request_quote")}>{residence_component.summary.join_the_list_cta}</a>
                                </div>
                                <Modal className="Estimate_modal Request_quote" show={this.state.show === "Request_quote"} onHide={this.handleClose} >
                                    <Modal.Header closeButton></Modal.Header>
                                    <Modal.Body>
                                        <SidebarSignUp sidebar_data={residence_component.summary.sidebar_form} communityOfInterest={residence_component.common_detail.communityOfInterest} nbd_title={residence_component.common_detail.nbd_title} division_name={residence_component.common_detail.division_name}/>
                                    </Modal.Body>
                                </Modal>
                                </div>
                            ):('')
                        ]:[
                            residence_component.summary.request_quote_cta && (residence_component.summary.request_quote_heading || residence_component.summary.request_quote_discription) && residence_component.summary.hide_quota_cta != true  ? (
                                <div className="detail-content">
                                    <div className="detail-text">
                                        <div className="detail-title">{residence_component.summary.request_quote_heading}</div>
                                        <p className="detail-description">{residence_component.summary.request_quote_discription}</p>
                                    </div>
                                    <div className="residence-btn">
                                        <a href="javascript:void(0)" className="resisdence-save-btn btn-color" title="Request Quote" onClick={() => this.handleShow("Request_quote")}>{residence_component.summary.request_quote_cta}</a>
                                    </div>
                                    <Modal className="Estimate_modal Request_quote" show={this.state.show === "Request_quote"} onHide={this.handleClose} >
                                        <Modal.Header closeButton></Modal.Header>
                                        <Modal.Body>
                                            <SidebarSignUp sidebar_data={residence_component.summary.sidebar_form} communityOfInterest={residence_component.common_detail.communityOfInterest} nbd_title={residence_component.common_detail.nbd_title} division_name={residence_component.common_detail.division_name}/>
                                        </Modal.Body>
                                    </Modal>
                                </div>
                            ):('')
                        ],
                        residence_component.common_detail.tour_details.show_tour_btn && residence_component.summary.schedule_cta && (residence_component.summary.schedule_heading || residence_component.summary.schedule_discription)  ? (
                            <div className="detail-content">
                                <div className="detail-text">
                                    <div className="detail-title">{residence_component.summary.schedule_heading}</div>
                                    <p className="detail-description">{residence_component.summary.schedule_discription}</p>
                                </div>
                                <div className="residence-btn">
                                    <a href="javascript:void(0)" className="btn-color" title="Schedule a tour" data-fancybox="tour_form" data-src="#tour_form" >{residence_component.summary.schedule_cta}</a>
                                    <ScheduleTourForm form_details={residence_component.common_detail.tour_details} division_name={residence_component.common_detail.division_name}/>
                                </div>
                            </div>
                         ):(''),
                        (residence_component.summary.get_prequalified_link && residence_component.summary.get_prequalified_cta) && (residence_component.summary.get_prequalified_heading || residence_component.summary.get_prequalified_description)  && residence_component.summary.hide_get_pre_qualified_cta != true  ? (
                            <div className="detail-content">
                                <div className="detail-text">
                                    <div className="detail-title">{residence_component.summary.get_prequalified_heading}</div>
                                    <p className="detail-description">{residence_component.summary.get_prequalified_description} </p>
                                </div>
                                <div className="residence-btn">
                                    <a href={residence_component.summary.get_prequalified_link} className="resisdence-save-btn btn-color" target="_blank" title="Get Pre-Qualified">{residence_component.summary.get_prequalified_cta}</a>
                                </div>
                            </div>
                        ):('')
                    ]:[
                        <div className="detail-content">
                            <p dangerouslySetInnerHTML={{__html:residence_component.summary.summary_subtext.replace(/(?:\r\n|\r|\n)/g, '')}}></p>
                        </div>,
                        residence_component.common_detail.post_status == 'Temporarily Sold Out' || residence_component.common_detail.post_status == 'Coming Soon' ? [
                            residence_component.summary.join_the_list_cta && (residence_component.summary.join_the_list_heading || residence_component.summary.join_the_list_description) ? (
                                <div className="detail-content">
                                    <div className="detail-text">
                                        <div className="detail-title">{residence_component.summary.join_the_list_heading}</div>
                                        <p className="detail-description">{residence_component.summary.join_the_list_description}</p>
                                    </div>
                                    <div className="residence-btn">
                                    <a href="javascript:void(0)" className="resisdence-save-btn btn-color" title="Join The Interest List" onClick={() => this.handleShow("Request_quote")}>{residence_component.summary.join_the_list_cta}</a>
                                </div>
                                <Modal className="Estimate_modal Request_quote" show={this.state.show === "Request_quote"} onHide={this.handleClose} >
                                    <Modal.Header closeButton></Modal.Header>
                                    <Modal.Body>
                                        <SidebarSignUp sidebar_data={residence_component.summary.sidebar_form} communityOfInterest={residence_component.common_detail.communityOfInterest} nbd_title={residence_component.common_detail.nbd_title} division_name={residence_component.common_detail.division_name}/>
                                    </Modal.Body>
                                </Modal>
                                </div>
                            ):('')
                        ]:['']
                    ]}
                    </div>
                </div>
            </div>
        );
    }
}
export default ResidenceScreen5; 
