import React from 'react';

import HeadingSection from './HeadingSection';
import './TeamSection.scss';

class TeamSection extends React.Component {

  render() {
    var data = this.props.team_data;
    var backgroundImage = data.side_image;
    if(backgroundImage){
        var background_image = {
            backgroundImage:'url('+backgroundImage+')',
        }
    }
    return (
      (data) ? (
        <section className="team-section">
          <div className="container" style={background_image}>
            <div className="row">
              <div className="col-lg-12">
                <div className="header-section">
                  <div className="heading-section-left">
                    <HeadingSection
                      subtitle={data.sub_text}
                      title_part_1={data.headline}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {data.team_members.map(items => (
                <div className="col-md-4 col-xs-12 col-sm-6">
                  <div className="team-info">
                    <div className="team-img">
                      {items.emp_image ? (
                        <img src={items.emp_image} title={items.emp_name} alt={items.emp_name} />
                      ) : ('')}
                    </div>
                    {items.emp_name ? (
                      <h3>{items.emp_name}</h3>
                    ) : ('')}
                    {items.emp_position ? (
                      <span>{items.emp_position}</span>
                    ) : ('')}
                    {items.emp_bio ? (
                      <p className="team-text" dangerouslySetInnerHTML={{ __html: items.emp_bio.replace(/(?:\r\n|\r|\n)/g, '') }}></p>
                    ) : ('')}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      ) : ('')
    )
  }
}
export default TeamSection;

