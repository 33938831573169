import React from 'react';
import './Residence.scss';
import Carousel from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ScheduleTourForm from './ScheduleTourForm.jsx';
import '../../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.css'
import GalleryModal from './GalleryModal'
import MortgageSection from './MortgageSection';
import { Modal } from 'react-bootstrap';
import $ from 'jquery';


class ResidenceScreen1 extends React.Component {
    constructor() {
        super();
        
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    
        this.state = {
          show: null
        };
    }
    
    handleClose() {
    this.setState({show: null});
    }

    handleShow(id) {
    this.setState({show: id});
    }
    componentDidMount() {
        $('.residence-header').removeClass('bdx-added');
    }
    PDFDownload_data_layer_push(param) {
        this.setState({ value:param })
    //     if(param && param==='PDFDownload') {
    //         window.dataLayer.push({'event':'PDFDownload'});
    //  }
    }
    EnvisionPresaleLaunch_data_layer_push(param){
        this.setState({ value:param })
    //     if(param && param==='EnvisionPresaleLaunch') {
    //         window.dataLayer.push({'event':'EnvisionPresaleLaunch'});
    //  }
    }
    render() {
        var window_width = window.screen.width;
        var residence_component = this.props.residence_component;
        const slider1Settings = {
            dots: true,
            infinite:false
        }
        return (
            <div className="residence-details" id="screen-1">
                <div className="residence-image">
                    {residence_component.detail.detail_hero || residence_component.detail.gallery_images.length > 0 || residence_component.detail.video_block ? (
                        <Carousel  {...slider1Settings} >
                            {residence_component.detail.detail_hero ? ( 
                            residence_component.detail.detail_hero_type === 'iframe' ? (
                                    <div className="iframe-div">
                                        <iframe src={residence_component.detail.detail_hero} title="Plan iframe"></iframe>
                                    </div>
                                ):(
                                    <div className="image-div">
                                        <img src={residence_component.detail.detail_hero} alt="Plan Gallery" title="Plan Gallery" />
                                    </div>
                                )
                            ):('')}
                            {residence_component.detail.gallery_images.length > 0 ? [
                                residence_component.detail.gallery_images.map(items => ( 
                                    <div>
                                        <img src={items.image.url} alt="gallery" title="gallery" />
                                    </div>
                                ))
                            ]:['']}
                            {residence_component.detail.video_block ? ( 
                                <div className="iframe-div">
                                    <iframe src={residence_component.detail.video_block} title='Video'></iframe>
                                </div>
                            ):('')}
                        </Carousel> 
                    ):(
                        <div className="image-div">
                            <img src={residence_component.detail.feature_image ? residence_component.detail.feature_image[0] : require('../../assets/images/TPH-default-image2.gif').default} alt="Plan Gallery" title="Plan Gallery" />
                            </div>
                    )}
                </div>
                <div className="residence-content">
                    {residence_component.common_detail.post_status ? ( 
                        residence_component.common_detail.post_status === 'Temporarily Sold Out' ? (
                            <div className={"plan-status status_gray"}>{residence_component.common_detail.post_status}</div>
                        ):(
                            <div className="plan-status">{residence_component.common_detail.post_status}</div>
                        )
                    ):('')}
                    {residence_component.common_detail.res_title ? ( 
                        <h1 className="plan-name">{residence_component.common_detail.res_title}</h1>
                    ):('')}
                    {residence_component.common_detail.price_value && residence_component.common_detail.post_status !== "Sold Out" && residence_component.common_detail.post_status !== "Temporarily Sold Out"  ? ( 
                        <div className="plan-price">{residence_component.common_detail.price_value}</div>
                    ):('')}
                    {residence_component.common_detail.description ? (
                     <div className="plan-description" dangerouslySetInnerHTML={{__html:residence_component.common_detail.description.replace(/(?:\r\n|\r|\n)/g, '')}}></div>
                     ):('')}
                    <div className="floor_bottomBtn">
                        <ul className="interactive-icons">
                            {residence_component.common_detail.tour_details.show_tour_btn ? (
                                <li>
                                    <a href="javascript:void(0)" title="Schedule a tour" data-fancybox="tour_form" data-src="#tour_form" className="interactive-btn btn-color">Schedule a tour</a>
                                    <ScheduleTourForm form_details={residence_component.common_detail.tour_details} division_name={residence_component.common_detail.division_name}/>
                                </li>
                            ):('')}
                            {residence_component.common_detail.brouchure ? (
                                <li>
                                    {/* <span><i className="fa fa-download" aria-hidden="true"></i></span> */}
                                    <a href={residence_component.common_detail.brouchure} title="Download" target="_blank" className="interactive-btn btn-color" onClick={() => this.PDFDownload_data_layer_push('PDFDownload')}>DOWNLOAD BROCHURE</a>
                                </li>
                            ):('')}
                            {residence_component.common_detail.envision_url && residence_component.common_detail.show_envision != false ? [
                            <li>
                                {/* <span><i className="fa fa-calculator" aria-hidden="true"></i></span> */}
                                <a href={residence_component.common_detail.envision_url} target="_blank" title="Online Design Studio" className="interactive-btn btn-color" onClick={() => this.EnvisionPresaleLaunch_data_layer_push('EnvisionPresaleLaunch')}>Visit the online design studio</a>
                            </li>,
                            <Modal className="Estimate_modal envision_modal" show={this.state.show === "Design_studio"} onHide={this.handleClose} >
                                    <Modal.Header closeButton></Modal.Header>
                                    <Modal.Body>
                                    {residence_component.common_detail.envision_url ? (
                                        <iframe src={residence_component.common_detail.envision_url} className="envision_frame" title="Online Design Studio" ></iframe>
                                    ):('')}
                                    </Modal.Body>
                            </Modal>
                            ]:['']}
                            {/* {video_data.video_link ? ( */}
                                <Modal className="Estimate_modal" show={this.state.show === "Estimate"} onHide={this.handleClose} >
                                    <Modal.Header closeButton></Modal.Header>
                                    <Modal.Body>
                                        <MortgageSection mortgage_data={residence_component.common_detail.mortgage_data}/>
                                    </Modal.Body>
                                </Modal>
                            {/* ):('')} */}
                            {/* <li>
                                <span><i className="fa fa-share-alt" aria-hidden="true"></i></span>
                                <a href="#" title="Share">Share</a><span> this home with others</span>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}
export default ResidenceScreen1; 
