import React from 'react';
import $ from 'jquery';
import './Blogs.scss';
import { Link } from 'react-router-dom'
class LifestyleGlobal extends React.Component {
  state = {
    current_blog_post: this.props.updated_lifestyle_post,
    selectedCat: {}
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.updated_lifestyle_post !== this.props.updated_lifestyle_post) {
      this.setState({
        current_blog_post: this.props.updated_lifestyle_post
      })
    }
  }
  componentDidMount(){
    const search_param = new URLSearchParams(window.location.search);
    const temp = search_param.has('category') ? this.props.category_list.filter(item=> item.slug === search_param.get('category'))[0]:this.props.category_list[0]

    
    this.update_posts(temp)
  }
  update_posts = (selected_cat) => {
    const current_posts = this.props.updated_lifestyle_post.filter((item, i) => item.all_cat.includes(selected_cat.name))
    this.setState({
      current_blog_post: current_posts,
      selectedCat: selected_cat
    })
  }

  handleCatClick = (e) => {
    this.update_posts(this.props.category_list[e.target.selectedIndex])
  }

  render() {
    const { category_list } = this.props;
    const { current_blog_post, selectedCat } = this.state
    var toShow;
    var window_width = window.screen.width;  
    return !current_blog_post || !current_blog_post.length ? null :
    (
            <div className="blog_listing_container lifestyle-global-div blogs-section">
              {(window_width > 991) ? ( 
              <div className="category_list">
                {category_list.map((item, i) => {
                  return item.name != null ? (
                  <div className={`cat_list_item ${selectedCat.slug === item.slug ? "active" : ""}`} key={i} onClick={() => this.update_posts(item)}>{item.name.replace("&amp;", "&")}</div>
                  ):('')
                })}
              </div>
              ):(   
                <div className="category_list">
                  <select name="category_dropdown_list" className="category_dropdown_list" style={{
                      backgroundImage:'url('+require('../../assets/images/angle-arrow-down.png')+')',
                  }} onChange={this.handleCatClick}>
                  {category_list.map((item, i) => {
                    return item.name != null ? ( <option 
                    value={item.name.replace("&amp;", "&")} 
                    selected={selectedCat.slug === item.slug} 
                    className={`cat_list_item ${selectedCat.slug === item.slug ? "active" : ""}`} 
                    key={i}>
                    {item.name.replace("&amp;", "&")}
                    </option> ):('')
                  })
                  }
                  </select>
                </div>
              )}
              <div className="card_heading_content">
                {selectedCat.name ? (
                  <h4 className="brand_heading">{selectedCat.name.replace("&amp;", "&")}</h4>
                ):('')}
                {selectedCat.description ? (
                  <h2 className="latest_post_heading" dangerouslySetInnerHTML={{ __html: selectedCat.description.replace(/(?:\r\n|\r|\n)/g, '') }}></h2>
                ):('')} 
              </div>
              <div className="blog_card_container">
                {current_blog_post.map((item, i) => (
                    <div className="blog_card" key={i}>
                      {item.post_thumbnail_url ? (
                      <Link to={item.post_url}>
                        <div className="blog_card_img">
                          <img src={item.post_thumbnail_url} title={item.post_title} alt={item.post_title} />
                        </div>
                      </Link>
                       ) : ('')}
                      <div className="blog_card_content">
                        {selectedCat.name ? (
                          <div className="blog_card_category">
                            {selectedCat.name.replace("&amp;", "&")}
                          </div>
                        ) : ('')}
                        {item.post_title ? (
                          <div className="blog_card_content_caption">
                            <h4>{item.post_title}</h4>
                          </div>
                        ) : ('')}
                        {item.post_content ? (
                          toShow = item.post_content.substring(0, 150) + "...",
                          <div className="blog_card_content_para">
                            <div dangerouslySetInnerHTML={{ __html: toShow.replace(/(?:\r\n|\r|\n)/g, '') }} />
                          </div>
                        ) : ('')}
                        {item.post_url ? (
                          <div className="read_more_btn">
                            <Link to={item.post_url} className="read_more">Read More</Link>
                          </div>
                        ) : ('')}
                      </div>
                    </div>
                ))}
              </div>
            
            </div>
      );
  }
}

export default LifestyleGlobal;