import React from 'react';
import './VideoBlock.scss';
import HeadingSection from './HeadingSection';
import DirectionMapContainer from './DirectionMapContainer';

class DirectionMapBlock extends React.Component {

  render() {
    var direction_details = this.props.direction_details;
    var direction_map_data = direction_details.direction_map_data;

    return (
      direction_map_data ? (
        direction_map_data.map_description || direction_map_data.map_sub_text || direction_map_data.map_headline ? (
          // <div className="container">
            <div className="video-main-div direction-map-div">
              <div className="video-iframe divide-equal" >
                {direction_map_data.map_link ? (
                  <iframe src={direction_map_data.map_link} className="direction_map" title="direction map" ></iframe>
                ) : (
                    <DirectionMapContainer direction_details={direction_details} />
                  )}
              </div>
              <div className="video-info divide-equal">
                <HeadingSection
                  subtitle={direction_map_data.map_sub_text}
                  title={direction_map_data.map_headline}
                  highlight_class='orange-color'
                  highlight_dir='left'
                  highlighted_word_count=''
                  text_class=''
                />
                {direction_map_data.map_description ? (
                  <p className="title-info" dangerouslySetInnerHTML={{ __html: direction_map_data.map_description.replace(/(?:\r\n|\r|\n)/g, '') }}></p>
                ) : ('')}
                {direction_map_data.cta_label && direction_map_data.cta_link ? (
                  <a href={direction_map_data.cta_link} className="learn-more btn-color" title={direction_map_data.cta_label}>{direction_map_data.cta_label}</a>
                ) : ('')}
              </div>
            </div>
          // </div>
        ) : ('')
      ) : ('')
    );
  }
}

export default DirectionMapBlock; 