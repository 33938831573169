import React from 'react';
import './CommBanner.scss';
import SidebarSignUp from './SidebarSignUp.jsx';
import { Modal } from 'react-bootstrap';
import $ from 'jquery';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ScheduleTourForm from './ScheduleTourForm.jsx';
import QmiReserveForm from './QmiReserveForm.jsx';
import '../../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.css'

const comm_options = {
    loop : true,
    nav : true,
    margin:1,
    items: 1,
    dots: false,
    autoplay:true,
    autoplayHoverPause:true
}

class CommBanner extends React.Component {

    constructor() {
        super();
        this.state = {
            show: null
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        this.setState({ show: null });
    }

    handleShow(id) {
        this.setState({ show: id });
    }
    componentDidMount() {
        if(window.location.href.indexOf("#interest-form") > -1) {
            document.getElementById("interest_click_btn").click();
        }
    }
    handleSelect(){
        console.log("==== on changed ====");
        if($(".owl-item").hasClass( "active" )){
            var iframe = $('.owl-item.active .hero_iframe');
            if(iframe != 'undefined'){
                var src = $(iframe).attr("src");
                $(iframe).attr("src",src);
            }
        }
    }
    split_string(str,count=2,dir='left',splitBy=' ') {
        var part1,part2;
        var split_array = str.split(splitBy);
        if(dir=='right') {
            part2 = split_array.splice(split_array.length-count).join(splitBy);
            part1 = split_array.join(splitBy);
        }else{
            part1 = split_array.splice(0,count).join(splitBy);
            part2 = split_array.join(splitBy);  
        }
        return [part1,part2];
      }
      direction_data_layer_push(param) {
        this.setState({ value:param })
        // if(param && param==='drivingDirections') {
        //     window.dataLayer.push({'event':'drivingDirections'});
        // }
     }
    render() {
 
        var community_details = this.props.community_details; 
        var posttype = this.props.posttype; 
        var comm_title =  community_details.title && this.split_string(community_details.title); 
        var highlight_class = 'green-color';
        var text_class = 'gray-color';
        var url_split,id_url ; 
        var backgroundImage = community_details.banner_image;

        if(backgroundImage !==""){
            var background_image = {
                backgroundImage:'url('+backgroundImage+')',
            }
        }
        return (
            <div className="Banner-Main">
                <div className="banner-top-section">
                    <div className="banner-first-section">
                        <div className="banner-left-section">
                            {comm_title ? (
                                    <h2 className={"comm-name "+(text_class || '')}><span className={highlight_class || ''}>{comm_title[0]}</span> {comm_title[1]}</h2>
                            ):('')}
                            {community_details.city || community_details.state_title ? (
                                <h1 className="comm-price">
                                    {(community_details.title== 'Avid' || community_details.title== 'Vita' || community_details.title== 'Mira' || community_details.title== 'Elan' || community_details.title== 'Altis' || community_details.title=='Altis at Terramor' || community_details.title=='Altis Beaumont' || community_details.title=='Altis at Skyline') ? (
                                        'New 55+ Homes in '+community_details.city+', '+community_details.state_title+'. '
                                    ):(
                                        'New homes in '+community_details.city+', '+community_details.state_title+'. '
                                    )}
                                    {community_details.price_value && community_details.community_status !== "Sold Out" && posttype !=="neighborhood"? (
                                        " "+community_details.price_value
                                    ):('')}
                                    {community_details.price_value && posttype === "neighborhood"? (
                                        " "+community_details.price_value
                                    ):('')}
                                </h1>
                            ):('')}
                            {posttype === "neighborhood" ? [
                                community_details.community_status ? (
                                    <span class="card-status">{community_details.community_status}</span>
                                ):(''),
                                community_details.neighborhood_marketing_headline ? (
                                    <h2 class="comm-price marketing-headline">{community_details.neighborhood_marketing_headline}</h2>
                                ):('')
                            ]:['']}
                        </div>
                        <ul className="comm-info">
                            {community_details.square_feet ? (
                                <li>
                                    <span className="info-value">{community_details.square_feet}</span>
                                    <span className="info-name">Sq.{'\u00A0'}Footage</span>
                                </li>
                            ):('')}
                            {community_details.bed ? (
                                <li>
                                    <span className="info-value">{community_details.bed}</span>
                                    <span className="info-name">
                                        {community_details.bed === '1' ? ('Bedroom'):('Bedrooms')}
                                    </span>
                                </li>
                            ):('')}
                            {community_details.bath ? (
                                <li>
                                    <span className="info-value">{community_details.bath}</span>
                                    <span className="info-name">
                                        {community_details.bath === '1' ? ('Bathroom'):('Bathrooms')}
                                    </span>
                                </li>
                            ):('')}
                            {community_details.garage ? (
                                <li>
                                    <span className="info-value">{community_details.garage}</span>
                                    <span className="info-name">Garage</span>
                                </li>
                            ):('')}
                            {posttype === "qmi"? [
                                community_details.plan_type ? (
                                    <li>
                                        <span className="info-value">{community_details.plan_type}</span>
                                        <span className="info-name">Plan</span>
                                    </li>
                                ):(''),
                                community_details.move_in_date ? (
                                    <li>
                                        <span className="info-value">{community_details.move_in_date}</span>
                                        <span className="info-name">move{'\u00A0'}-{'\u00A0'}in</span>
                                    </li>
                                ):('')
                            ]:['']}
                        </ul>
                    </div>
                    <div className="banner-second-section">
                       
                        <div className="banner-bottom-section">
                            <ul>
                                {/* {community_details.community_status ? (
                                    <li className="comm-status">{community_details.community_status}</li>
                                ):('')} */}
                                {community_details.hide_address !== true ? [
                                    <div className='banner-address'>
                                        {community_details.sales_office_address.sales_address || community_details.sales_office_address.sales_address_heading ? [
                                        <p className="divide-equal sales-address">
                                            {posttype === "qmi" || posttype === "neighborhood" ? (
                                                community_details.community_status != 'Temporarily Sold Out' ? (
                                                    community_details.sales_office_address.sales_address_heading ?(
                                                        <li className="time" dangerouslySetInnerHTML={{__html:community_details.sales_office_address.sales_address_heading.replace(/(?:\r\n|\r|\n)/g, '')}}></li> 
                                                    ):('')
                                                ):( 
                                                    <li className="time">Our New Home Gallery is located at</li> 
                                                )
                                            ):(
                                                community_details.sales_office_address.sales_address_heading ?(
                                                    <li className="time" dangerouslySetInnerHTML={{__html:community_details.sales_office_address.sales_address_heading.replace(/(?:\r\n|\r|\n)/g, '')}}></li>
                                                ):('')
                                            )}
                                            {community_details.sales_office_address.sales_address ?(
                                                <li className="time">{community_details.sales_office_address.sales_address}</li>
                                            ):('')}
                                        </p>
                                        ]:['']}

                                        {community_details.physical_address.comm_address || community_details.physical_address.physical_address_heading ? [
                                        <p className="divide-equal">
                                            {posttype === "qmi" || posttype === "neighborhood" ? (
                                                community_details.community_status != 'Temporarily Sold Out' ? (
                                                    community_details.physical_address.physical_address_heading ?(
                                                        <li className="time" dangerouslySetInnerHTML={{__html:community_details.physical_address.physical_address_heading.replace(/(?:\r\n|\r|\n)/g, '')}}></li>
                                                    ):('')
                                                ):( 
                                                    <li className="time">Our New Home Gallery is located at</li> 
                                                )
                                            ):(
                                                community_details.physical_address.physical_address_heading ?(
                                                    <li className="time" dangerouslySetInnerHTML={{__html:community_details.physical_address.physical_address_heading.replace(/(?:\r\n|\r|\n)/g, '')}}></li>
                                                ):('')
                                            )}
                                            {community_details.physical_address.comm_address ?(
                                                <li className="time">{community_details.physical_address.comm_address}</li>
                                            ):('')}
                                        </p>
                                        ]:['']}
                                    </div>,
                                    community_details.sales_office_address.sales_address || community_details.sales_office_address.sales_address_heading ? (
                                        community_details.sales_office_address.custom_direction_link ?(
                                            <a href={community_details.sales_office_address.custom_direction_link} target="_blank" rel="noopener noreferrer"  className="driving-directions" title="driving directions"  onClick={() => this.direction_data_layer_push('drivingDirections')}>Get Directions</a>
                                        ):('')
                                    ):( 
                                        community_details.physical_address.map_directions_link ?(
                                            <a href={community_details.physical_address.map_directions_link} target="_blank" rel="noopener noreferrer"  className="driving-directions" title="driving directions"  onClick={() => this.direction_data_layer_push('drivingDirections')}>Get Directions</a>
                                        ):('')
                                    )
                                ]:['']}
                                {/* {posttype === "qmi" || posttype === "neighborhood" ? (
                                    community_details.time_hour ?(
                                        community_details.community_status != 'Temporarily Sold Out' ? (
                                            <li className="city">{community_details.time_hour}</li>
                                        ):(
                                            <li className="city">open by appointment only</li>
                                        )
                                    ):('')
                                ):( */}
                                    {community_details.time_hour ?(
                                        <li className="city">{community_details.time_hour}</li>
                                    ):('')}
                                {/* )} */}
                            </ul>
                        </div>
                    
                        {posttype === "qmi"? [
                            community_details.tour_details.show_tour_btn && community_details.qmi_status !== 'Coming Soon' ? [
                                community_details.schedule_button_text ? (
                                    <div className="schedule_tour_div">
                                        <a href="#tour_form" data-fancybox="tour_form" data-src="#tour_form" 
                                        className="tour_filled_btn" id="tour_click_btn">{community_details.schedule_button_text}</a>
                                    </div>
                                ):(''),
                                <QmiReserveForm form_details={community_details.tour_details} agent_name={community_details.agent_info} division_name={community_details.division_name} homesiteNumber={community_details.homesite_number}/>
                            ]:[
                                <div className="schedule_tour_div">
                                    <a href="javascript:void(0)" className="tour_filled_btn" title="Join The Interest List" onClick={() => this.handleShow("Request_quote")} id="interest_click_btn">Join The Interest List</a>
                                </div>,
                                <Modal className="Estimate_modal Request_quote" show={this.state.show === "Request_quote"} onHide={this.handleClose} >
                                    <Modal.Header closeButton></Modal.Header>
                                    <Modal.Body>
                                        <SidebarSignUp sidebar_data={community_details.sidebar_form} communityOfInterest={community_details.communityOfInterest} nbd_title={community_details.neigh_title} division_name={community_details.division_name}/>
                                    </Modal.Body>
                                </Modal>
                            ]
                        ]:[
                            posttype === "neighborhood"? [
                                community_details.community_status !== 'Coming Soon' && community_details.community_status !== 'Temporarily Sold Out' && community_details.tour_details.show_tour_btn ?[
                                    <div className="schedule_tour_div">
                                        <a href="#tour_form" data-fancybox="tour_form" data-src="#tour_form" 
                                        className="tour_filled_btn" title="Schedule a Tour" id="tour_click_btn">Schedule a Tour</a>
                                    </div>,
                                    <ScheduleTourForm form_details={community_details.tour_details} division_name={community_details.division_name} />
                                ]:[
                                    <div className="schedule_tour_div">
                                     {community_details.community_status !== 'Sold Out' ? (
                                        <a href="javascript:void(0)" className="tour_filled_btn" title="Join The Interest List" id="interest_click_btn" onClick={() => this.handleShow("Request_quote")}>Join The Interest List</a>
                                    ):('')}
                                    </div>,
                                    <Modal className="Estimate_modal Request_quote" show={this.state.show === "Request_quote"} onHide={this.handleClose} >
                                        <Modal.Header closeButton></Modal.Header>
                                        <Modal.Body>
                                            <SidebarSignUp sidebar_data={community_details.sidebar_form} communityOfInterest={community_details.communityOfInterest} nbd_title={community_details.title} division_name={community_details.division_name} neigh_status={community_details.community_status}/>
                                        </Modal.Body>
                                    </Modal>
                                ]
                            ]:[
                                community_details.community_status !== 'Coming Soon' && community_details.tour_details.show_tour_btn ?[
                                    <div className="schedule_tour_div">
                                        <a href="#tour_form" data-fancybox="tour_form" data-src="#tour_form" 
                                        className="tour_filled_btn" title="Schedule a Tour" id="tour_click_btn">Schedule a Tour</a>
                                    </div>,
                                    <ScheduleTourForm form_details={community_details.tour_details} division_name={community_details.division_name}/>
                                ]:[
                                    <div className="schedule_tour_div">
                                    {community_details.community_status !== 'Sold Out' ? (
                                        <a href="javascript:void(0)" className="tour_filled_btn" title="Join The Interest List" id="interest_click_btn" onClick={() => this.handleShow("Request_quote")}>Join The Interest List</a>
                                    ):('')}
                                    </div>,
                                    <Modal className="Estimate_modal Request_quote" show={this.state.show === "Request_quote"} onHide={this.handleClose} >
                                        <Modal.Header closeButton></Modal.Header>
                                        <Modal.Body>
                                            <SidebarSignUp sidebar_data={community_details.sidebar_form} communityOfInterest={community_details.communityOfInterest} nbd_title={community_details.title} division_name={community_details.division_name}/>
                                        </Modal.Body>
                                    </Modal>
                                ]
                            ]
                        ]}
                    </div>
                </div>
               
                <div className="banner-img-section" style={background_image}>
                {community_details.master_community_snipe && <div className="brand-logo">
                    <img src={community_details.master_community_snipe.url} alt={community_details.master_community_snipe.title} title={community_details.master_community_snipe.title}/>
                </div>}
                {community_details.show_slider_data.length > 0 ? (
                    <div className="banner-slider">
                        <OwlCarousel {...comm_options} className="owl-theme" onChanged={() => this.handleSelect()}>  
                            {community_details.show_slider_data.map(item => (
                                item.slider_image ? (
                                <div>
                                    <img src={item.slider_image} alt="Banner" title="banner"/>
                                    <div className="item-bg-overlay"></div>
                                    <div className="item-caption">
                                        {item.slider_heading ? (
                                            <h2 dangerouslySetInnerHTML={{__html:item.slider_heading.replace(/(?:\r\n|\r|\n)/g, '')}}></h2>
                                        ):('')}
                                        {item.slider_subheading ? (
                                            <p>{item.slider_subheading}</p>
                                        ):('')}
                                        {item.slider_link ? (
                                            <a href={item.slider_link} className="item-btn" title="learn more">Learn More</a>
                                        ):('')}
                                    </div>
                                </div> 
                                ):(
                                    item.slider_video ? (
                                        url_split = item.slider_video.split("/"),
                                        id_url = url_split[url_split.length - 1],
                                        <div>
                                            <iframe className="hero_iframe" src={item.slider_video+'?playlist='+id_url} title="banner iframe"></iframe>
                                        </div> 
                                    ):('')
                                )
                            ))}  
                        </OwlCarousel>
                    </div>
                ):('')}
                </div>
            </div> 
     );  
  }  
}  

export default CommBanner; 