import React from "react";
import "./PostSection.scss";
import HeadingSection from "./HeadingSection";

class PostSection extends React.Component {
  constructor() {
    super();
    this.state = { loaded: "col-md-8 col-sm-12 col-xs-12 horizontal-img" };
  }

  render() {
    var post = this.props.post_data;
    var anchor=this.props.anchor_tag;
  
    var target_change;
    if( anchor == true) {
      target_change = "_blank";
  } else {
      target_change = "";
  }
    var layout_class =
      this.props.post_data.post_list.length < 3 ? "less-gradient" : "";
    return post.post_list.length > 0 ? (
      <div className={"post-section " + (layout_class || "")}>
        <div className="container post-container">
          <div className="post-content">
            <div className="header-section">
              <div className="heading-section-left">
                <HeadingSection
                  subtitle={post.sub_text}
                  title_part_1={post.headline}
                />
              </div>
            </div>

            <div className="row">
              {post.post_list.map((items, key) => (
                <div
                  className={
                    (key === 0 && this.state.loaded) ||
                    "col-md-4 col-sm-12 col-xs-12 square-img"
                  }
                >
                  <div className="post-info">
                    <div className="post-img">
                      {items.main_image ? (
                        <img
                          src={items.main_image}
                          title={items.Category.replace("&amp;", "&")}
                          alt={items.Category.replace("&amp;", "&")}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    {items.Category ? (
                      <span>{items.Category.replace("&amp;", "&")}</span>
                    ) : (
                      ""
                    )}
                    {items.post_headline ? <h6>{items.post_headline}</h6> : ""}
                    {items.cta_link ? (
                      <a
                        href={items.cta_link}
                        title="Learn More"
                        className="learn-more cta_label_color" target={ target_change}
                      >
                        Learn More
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  }
}

export default PostSection;
