import React, { useState } from "react";
import axios from 'axios';
import $ from 'jquery';
import { Link } from 'react-router-dom'
import { withAuth0 } from "@auth0/auth0-react";
import OwlCarousel from 'react-owl-carousel';
import {apiConfig} from '../Components/Helper.jsx';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const plan_options = {
  loop: true,
  center:true,
  nav: true,
  dots: false,
  margin: 0,
  autoplay: true,
  responsive:{
      0:{
          items:1,
      },
      600:{
          items:2,
      }
  }
}

class FloorplansInfo extends React.Component {
  constructor() {
      super();
      this.state = {
        user_data: [],
        floorplans: [],
        data_load: false,
        isQueryStringProcessed:false
      };

  }

  card_height(){
    //Same Card Height
    var card_section1 = [];
    
    $(document).find(".user_floorplan").each(function() {
        card_section1.push($(this).find(".user_plan_details").outerHeight());
    });
    
    var card_maxheight1 = Math.max.apply(Math, card_section1);
    $(document).find(".user_plan_details").css("min-height", card_maxheight1);
  }


  componentDidMount() {
    //Floorplans List
    axios.post(apiConfig().API_URL+'/floorplan_list', {
      "altis_user_id":this.props.auth0.user.sub,
     },
     {
       headers: {
         Authorization: 'Aa147147147#'
       }
     }
    )
    .then(response => {
        this.setState({ 
          user_data: response.data.data,
          floorplans: response.data.data.user_metadata.altis_floorplans,
          data_load: response.data.success,
        });
    })

  }

  remove_plan(key){
    console.log("Called remove");
    var floorplans_array = this.state.floorplans;
    if (key > -1) {
      floorplans_array.splice(key, 1);
    }

    axios.post(apiConfig().API_URL+'/floorplan_multisave', {
      "altis_user_id":this.props.auth0.user.sub,
      "altis_floorplans":floorplans_array
     },
     {
       headers: {
         Authorization: 'Aa147147147#'
       }
     }
    )
    .then(response => {
        this.setState({ 
          floorplans: floorplans_array,
        });
    })

  }

  checkforQueryString(){
      if(this.state.isQueryStringProcessed == false &&  $(".user_floorplan").length > 0){
          console.log("loaded query");
        var card_section1 = [];      
        $(document).find(".user_floorplan").each(function() {
            card_section1.push($(this).find(".user_plan_details").outerHeight());
        });       
        var card_maxheight1 = Math.max.apply(Math, card_section1);
        $(document).find(".user_plan_details").css("min-height", card_maxheight1);

        this.setState({
            isQueryStringProcessed:true
        });
          
      }
  }

  componentDidUpdate(){
    this.checkforQueryString();
  }

  render(){
    var data_load = this.state.data_load;
    var floorplans = this.state.floorplans;
    return(
        <div className="user_floorplan_details">
              {
                data_load == true ? [
                  floorplans != undefined && floorplans.length > 0 ? [
                    <div className="user_floorplan_list">
                      {
                        window.innerWidth > 1025 ? [
                            floorplans.map((items,key) => (
                              <div className="user_floorplan">
                                <div className="user_fp_section">
                                    <img src={items.altis_image} alt={items.altis_title} title={items.altis_title}/>
                                    <div className="floor_overlay">
                                        <Link to={items.altis_url}>View</Link>
                                        <a href="javascript:void(0)" className="plan_delete" onClick={()=>{this.remove_plan(key)}}><i class="fa fa-trash-o" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                                <div className="user_plan_details">
                                  <h5>{items.altis_name}</h5>
                                  {/* {items.altis_price != '' ? (
                                    <p>Estimate Total: {items.altis_price}</p>
                                  ):('')} */}
                                </div>
                              </div>
                            ))
                        ]:[
                          <OwlCarousel {...plan_options} className="owl-theme">
                            {
                              floorplans.map((items,key) => (
                              <div className="user_floorplan">
                                <div className="user_fp_section">
                                    <img src={items.altis_image} alt={items.altis_title} title={items.altis_title}/>
                                    <div className="floor_overlay">
                                        <Link to={items.altis_url}>View</Link>
                                        <a href="javascript:void(0)" className="plan_delete" onClick={()=>{this.remove_plan(key)}}><i class="fa fa-trash-o" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                                <div className="user_plan_details">
                                  <h5>{items.altis_name}</h5>
                                  {/* {items.altis_price != '' ? (
                                    <p>Estimate{items.altis_price}</p>
                                  ):('')} */}
                                </div>
                              </div>
                            ))
                            }
                        </OwlCarousel>
                        ]
                      }
                    </div>
                ]:[
                  <p className="no_floorplans">No floorplans selected.</p>
                ]
                ]:[<div className="user_loader"><img src={require('../../assets/images/cube_loader.gif').default} /></div>]
              }
        </div>
    )
  }

}



// const FloorplansInfo = () => {
//   const { user } = useAuth0();
//   const [user_meta_data, setuser_meta_data] = useState([]);
//   const [data_load, setdata_load] = useState(false);



//   return (

//   );
// };

export default withAuth0(FloorplansInfo)