import React from 'react';
import './LivingSmart.scss';
import HeadingSection from './HeadingSection';

class LivingSmart extends React.Component {
  render() {
    var living_smart_data = this.props.living_smart_data;
    var backgroundImage = living_smart_data.side_pattern_image
    var target_change;
    if( living_smart_data.anchor_in_new_tab == true) {
      target_change = "_blank";
  } else {
      target_change = "";
  }
 
    if (living_smart_data.background_color) {
      var backgroundColor = {
        backgroundColor: living_smart_data.background_color
      }
    }  
    if (backgroundImage !== "") {
      var background_image = {
          backgroundImage: 'url(' + backgroundImage + ')',
      }
  }
    return (
      living_smart_data.image !== false && living_smart_data.headline && living_smart_data.description ? (
        <div className="living-section" style={{ ...background_image, ...backgroundColor }}>
          <div className="container">
            <div className="living-content">
              {living_smart_data.image !== false ? (
                <div className="living-img divide-equal">
                  <img src={living_smart_data.image.url} title={living_smart_data.image.title} alt={living_smart_data.image.title} />
                </div>
              ) : ('')}
              <div className="living-info divide-equal">
                <div className="header-section">
                  <HeadingSection
                    subtitle={living_smart_data.sub_text}
                    title_part_1={living_smart_data.headline}
                    title_part_2={living_smart_data.headline_part_2}
                    headline_part_1_color={living_smart_data.headline_part_1_color}
                    headline_part_2_color={living_smart_data.headline_part_2_color}
                  />
                  {living_smart_data.description ? (
                    <p className="title-info" dangerouslySetInnerHTML={{ __html: living_smart_data.description.replace(/(?:\r\n|\r|\n)/g, '') }}></p>
                  ) : ('')}
                  {living_smart_data.cta_label && living_smart_data.cta_link ? (
                    <a href={living_smart_data.cta_link} target={target_change}title={living_smart_data.cta_label}  className="learn-more btn-color">{living_smart_data.cta_label}</a>
                  ) : ('')}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : ('')
    );
  }
}

export default LivingSmart; 