import React from 'react'
import {BrowserRouter, Switch, Route, withRouter} from 'react-router-dom'
import HomePage from './pages/HomePage';
import Page_Decider from './pages/Page_Decider';
import StaticPage from './pages/StaticPage';
import BlogPage from './pages/BlogPage';
import BlogListing from './pages/BlogListing';
import EventsPage from './pages/EventsPage';
import EventDetailsPage from './pages/EventDetailsPage';
import LifestyleDetails from './pages/LifestyleDetails';
import DirectionPage from './pages/DirectionPage';
import ReviewPage from './pages/ReviewPage';
import NoMatch from './pages/NoMatch';
import MyAccount from './pages/MyAccount';
import ProtectedRoute from "../auth/protected-route";
class Views extends React.Component {  
    render() {  
       return (  
          <div className="main-outer-div">  
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/lifestyle/:slug" component={LifestyleDetails}/> 
              <ProtectedRoute  exact path="/my_account" component={MyAccount} /> 
              <Route exact path="/events" component={EventsPage} />
              <Route exact path="/event/:slug" component={EventDetailsPage}/>
              <Route exact path="/blog" component={BlogListing}/>
              <Route exact path="/blog/:slug" component={BlogPage}/>  
              <Route exact path="/:statename/:submarket/:slug" component={Page_Decider} />
              <Route exact path="/:statename/:submarket/:slug/directions" component={DirectionPage} />
              <Route exact path="/:statename/:submarket/:slug/reviews/" component={ReviewPage}/>
              <Route exact path="/:statename/:submarket/:neigh/:slug" component={Page_Decider}/>
              <Route exact path="/:slug" component={StaticPage}/>
              <Route component={NoMatch}/>
            </Switch>
          </div>  
       );  
    }  
}  
  
export default withRouter(Views);