import React from 'react';
import axios from 'axios';
import NoMatch from './NoMatch';
import GlobalField from '../../Components/GlobalField';
import {apiConfig} from '../../Components/Helper.jsx';
import TPHLoader from '../../Components/TPHLoader.jsx';
import { Redirect } from "react-router-dom";
import DocumentMeta from 'react-document-meta';
import $ from 'jquery';
class HomePage extends React.Component {

  constructor() {
    super();
    this.state = {
      show:true,
      home_component:'',
      plan_cards:'',
      success : false
    };
  }
  organization_structured_data(home_component){
    if(home_component && home_component.site_logo){
      var site_logo = home_component.site_logo;

    }
    const script = document.createElement("script");
   
    // Structure Data script start 
    script.type = "application/ld+json";
    script.innerHTML = `{
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "Pecan Ridge",
      "url": "${window.location.href}",
      "logo": "${site_logo}",
      "sameAs": [
        "https://twitter.com/tripointehomes",
        "https://www.youtube.com/channel/UCXJMVxh_N_bezo_r5m1TDhg",
        "https://www.pinterest.com/tripointehomes/"
      ]
    }`
      //  Structure Data script end 
    document.head.appendChild(script);

  }
  componentDidMount(){
    axios.get(apiConfig().API_URL+'/home_info/home')
    .then(response => {
      this.setState({
        home_component : response.data.data,
        success: response.data.success,
        message: response.data.message
      }, () => {
        if (this.state.success == true) {
          window.adminStyle = this.state.home_component && this.state.home_component.global_style;
          this.globalstylefun();
        } }
      )
      console.log(response);
    });
    window.globalstylefun = this.globalstylefun;
    document.addEventListener("click", function(e) {
      window.globalstylefun();
    });
    window.CallTrk.swap();
  }
  globalstylefun = () => {
    const globalstyle = window.adminStyle;
      if (globalstyle) {
        $('h2, .sectiontitle-headline').css({ "color": globalstyle.global_components_headline, "font-family": globalstyle.h2_fonts});
        $('h1').css({ "color": globalstyle.global_components_headline_h1, "font-family": globalstyle.h1_fonts });
        $('p').css({ "color": globalstyle.global_paragraph_text, "font-family": globalstyle.paragraph_fonts });
        $('.sub-title, .sectiontitle-eyebrow_text, .blog_card_category, .brand_heading').css("color", globalstyle.eyebrow_text_color);
        $('.btn-color').css("color", globalstyle.button_text_color);
        $('.btn-color').css({ "background-color": globalstyle.button_outlinebackground_color, "border": '1px solid' + globalstyle.button_outlinebackground_color });
        $('.cta_label_color').css({ "color": globalstyle.inline_linkscta, 'border-bottom': '2px solid' + globalstyle.inline_linkscta });
        $(".btn-color").mouseover(function () {
          $(this).css({ "background-color": globalstyle.button_hover, "border": '1px solid' + globalstyle.button_hover });
        }).mouseout(function () {
          $(this).css({ "background-color": globalstyle.button_outlinebackground_color, "border": '1px solid' + globalstyle.button_outlinebackground_color });
        });
      } 
  }
  componentDidUpdate(){
    window.CallTrk.swap();
  }
  render () {
    var success = this.state.success; 
    var message = this.state.message;
    var home_component = this.state.home_component;
    if(home_component){
      this.organization_structured_data(home_component);
    }
    const meta = {
      title: home_component.yoast_title,
      description: home_component.yoast_desc,
      canonical: window.location.href
    };
    return (
      <>
      <DocumentMeta {...meta} />
        {success === true ? [
      <GlobalField global_data={home_component.component}/>
        ]:[message != 'Not Completed' ?  <TPHLoader/>  :  <Redirect to={<NoMatch/>} />  ]} 
      </>
    )
  }
}

export default HomePage;
