import React from 'react';
import './Residence.scss';
import Carousel from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withAuth0 } from "@auth0/auth0-react";
import $ from 'jquery';

class ResidenceScreen4 extends React.Component {
    constructor() {
        super()

        this.state = {
            nav1: null,
            nav2: null,
            nav3: null,
            token: '',
            dreamweaver_load: false 
        }
        this.bdx_visualizer_plugin_load = this.bdx_visualizer_plugin_load.bind(this);
    }

    getUserMetadata = async () => {   
        const accessToken = await this.props.auth0.getAccessTokenSilently();
        this.setState({ 
            token: accessToken != '' ? accessToken : '',
            dreamweaver_load: true 
        });
    };

    bdx_visualizer_plugin_load(){
        var that = this;
        var payload = {
        visualizer:that.props.residence_component.room_visualizer.bdx_visualizer_id, 
        container: "visualizer-div",
        secretKey: 'envision',
        accessToken: this.state.token,
        authDomain: process.env.Auth0_Domain,
        };

     window.MEDIALOADER.init3DVisualizer(payload, function (data) {
        console.log("callback:", data);
    });
    this.setState({ 
        dreamweaver_load: false, 
    });
    }

    componentDidMount() {

        if(this.props.auth0.isAuthenticated == true){
            this.getUserMetadata();
        }
        else{
            this.setState({ 
                dreamweaver_load: true, 
            });
        }

      this.setState({
          nav1: this.slider1,
          nav2: this.slider2,
          nav3: this.slider3
      })   
      $('.residence-header').removeClass('bdx-added');
      if($('.residence-image').hasClass('bdx_parent_div')){
          $('.residence-header').addClass('bdx-added');
      }   
      
    }
    componentDidUpdate(){
        if(this.state.dreamweaver_load == true){
            if(this.props.residence_component.room_visualizer.show_visualizer_bdx == true && this.props.residence_component.room_visualizer.bdx_visualizer_id != ''){
                this.bdx_visualizer_plugin_load();
            }  
        }
    }
    render() {
        const slider1Settings = {
            asNavFor: this.state.nav2,
            ref: slider => (this.slider1 = slider),
            dots: true,
            infinite:false
        }
        const slider2Settings = {
            asNavFor: this.state.nav3,
            ref: slider => (this.slider2 = slider),
            arrows: false,
            dots: false,
            infinite:false,
            draggable: false,
            focusOnSelect: true
        }
        const slider3Settings = {
            asNavFor: this.state.nav1,
            ref: slider => (this.slider3 = slider),
            arrows: false,
            dots: false,
            infinite:false,
            draggable: false,
            fade: true,
            speed: 600
        }
        var residence_component = this.props.residence_component.room_visualizer;
        var bdx_class  = residence_component.show_visualizer_bdx == true && residence_component.bdx_visualizer_id != '' ? 'bdx_parent_div' : '';
        return (
            <div className="residence-details" id="screen-4">
            <div className={"residence-image "+bdx_class}> 
                {residence_component.visualizer_data || (this.props.residence_component.room_visualizer.show_visualizer_bdx == true && this.props.residence_component.room_visualizer.bdx_visualizer_id != '')? [
                    residence_component.show_visualizer_bdx == true && residence_component.bdx_visualizer_id != '' ? [
                        <div className="bdx_plugin_div iframe-div" id="visualizer-div"></div>
                    ]:[
                        <div className="iframe-div">
                            <iframe src={residence_component.visualizer_data} title="Plan iframe"></iframe> 
                        </div>
                    ]
                ]:['']}
            </div>
            {/* <div className="residence-content">
                <div className="plan-name">Room Visualizer</div>
                {residence_component.room_visualizer_subheading ? (
                    <div className="plan-description">
                    <p>{residence_component.room_visualizer_subheading }</p>
                </div>
                ):('')}
            
                {residence_component.visualizer_data.length > 0 ? [
                <div className="plan-btns">
                    <div className="thumbnail-slider-wrap">
                        <Carousel  {...slider2Settings} >
                            {residence_component.visualizer_data.map(items => ( 
                                <div className="residence-btns">
                                    <p className="plan-btn" title={items.asset_heading}>{items.asset_heading}</p>
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>,
                <div className="btn-description">
                    <Carousel  {...slider3Settings} >
                        {residence_component.visualizer_data.map(items => (
                        <div>
                            <div className="btn-name">{items.asset_description_heading}</div>
                            <p dangerouslySetInnerHTML={{__html:items.asset_description.replace(/(?:\r\n|\r|\n)/g, '')}}></p>
                        </div>
                        ))}
                    </Carousel>
                </div>
                ]:[]}
            </div> */}
        </div>
        );
    }
}
export default withAuth0(ResidenceScreen4); 
