import React from 'react';
import axios from 'axios';
import GlobalField from '../../Components/GlobalField';
import {apiConfig} from '../../Components/Helper.jsx';
import FaqSection from '../../Components/faqSection';
import TPHLoader from '../../Components/TPHLoader.jsx';
import NoMatch from './NoMatch';
import { Redirect } from "react-router-dom";
import DocumentMeta from 'react-document-meta';
import $ from 'jquery';
class StaticPage extends React.Component {  
  state ={
    static_component : [],
    success : false,
    message : '',
  }

  fetch_data(){
    axios.get(apiConfig().API_URL+'/static_info/'+ this.props.match.params.slug)
    .then(response => {
      this.setState({      
        static_component : response.data.data,
        success: response.data.success,
        message : response.data.message,
      }, () => {
        if (this.state.success == true) {
          window.adminStyle = this.state.static_component && this.state.static_component.global_style;
          this.globalstylefun();
        } }
      )
      console.log(response);
    })
  }

  componentDidMount() {
    this.fetch_data();
    window.globalstylefun = this.globalstylefun;
    document.addEventListener("click", function(e) {
      window.globalstylefun();
    });
    window.CallTrk.swap();
    window.scrollTo(0,0);
  }
  componentDidUpdate() {
    window.CallTrk.swap();
    window.scrollTo(0,0);
  }
  globalstylefun = () => {
    const globalstyle = window.adminStyle;
      if (globalstyle) {
        $('h2, .sectiontitle-headline').css({ "color": globalstyle.global_components_headline, "font-family": globalstyle.h2_fonts});
        $('h1').css({ "color": globalstyle.global_components_headline_h1, "font-family": globalstyle.h1_fonts });
        $('p').css({ "color": globalstyle.global_paragraph_text, "font-family": globalstyle.paragraph_fonts });
        $('.sub-title, .sectiontitle-eyebrow_text, .blog_card_category, .brand_heading').css("color", globalstyle.eyebrow_text_color);
        $('.btn-color').css("color", globalstyle.button_text_color);
        $('.btn-color').css({ "background-color": globalstyle.button_outlinebackground_color, "border": '1px solid' + globalstyle.button_outlinebackground_color });
        $('.cta_label_color').css({ "color": globalstyle.inline_linkscta, 'border-bottom': '2px solid' + globalstyle.inline_linkscta });
        $(".btn-color").mouseover(function () {
          $(this).css({ "background-color": globalstyle.button_hover, "border": '1px solid' + globalstyle.button_hover });
        }).mouseout(function () {
          $(this).css({ "background-color": globalstyle.button_outlinebackground_color, "border": '1px solid' + globalstyle.button_outlinebackground_color });
        });
      } 
  }
  componentWillReceiveProps(nextProps) {
    if(this.props.location.pathname != nextProps.location.pathname){
      this.props = nextProps;
      this.setState({
        success: false
      })
      this.fetch_data();
    }
    
  } 
  aba_data_layer_push(param) {
    this.setState({ value:param })
    // if(param && param==='Accept') {
    //     window.dataLayer.push({'event':'prequalification'});
    // }
}
  render() {    
    var success = this.state.success;
    var message = this.state.message; 
    var static_component = this.state.static_component;
    const meta = {
      title: static_component.page_title,
      description: static_component.page_desc,
      canonical: window.location.href
    };
     return (  
        <>
        <DocumentMeta {...meta} />
          {success === true ? [ 
            <div className="global-main"> 
                <GlobalField global_data={static_component.component}/>
                <FaqSection community_details={static_component}/>
                {static_component.static_content && <div className="staic-content" dangerouslySetInnerHTML={{__html:static_component.static_content.replace(/(?:\r\n|\r|\n)/g, '')}}></div>}
               
                  {static_component.static_button_content ? ( 
                    <div className="static_button_content">
                    {static_component.static_button_content.map(items => (
                      items.button_link && items.static_button ? (
                        <a href={items.button_link}  style={{backgroundColor:items.button_color }} className="learn-more" title={items.static_button} onClick={() =>  this.aba_data_layer_push(items.static_button)}>{items.static_button}</a>
                      ):('')
                    ))}
                    </div>
                  ):('')}
            </div>
          ] : [message != 'Not Completed' ?  <TPHLoader/>  :  <Redirect to={<NoMatch/>} /> ]}
        </>
        
     );  
  }  
}  

export default StaticPage; 