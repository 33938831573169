import React from 'react';
import {BrowserRouter} from 'react-router-dom'
// import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import Main from './Views/Views';
import Header from './Header/Header';
import Footer from './Footer/Footer';

class App extends React.Component { 

   componentDidMount(){
      window.CallTrk.swap();
      window.JQUERY('.header_section').show();
      window.JQUERY('.footer_section').show();
      window.JQUERY('.main-outer-div').removeClass('residence-top');
      window.JQUERY('.top-scroll').show();
      window.onpopstate = function(){
        window.JQUERY('.header_section').show();
        window.JQUERY('.footer_section').show();
        window.JQUERY('.top-scroll').show();
      }
   } 

  render() {   
    return (  
        <BrowserRouter>  
           <Header />
           {/* <MainPopup/> */}
            <Main />
            <Footer/>
         </BrowserRouter>  
    );  
 } 
}

export default App;
