import React from 'react';
import axios from 'axios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import GlobalField from '../../Components/GlobalField';
import { apiConfig } from '../../Components/Helper.jsx';
import TPHLoader from '../../Components/TPHLoader.jsx';
import moment from 'moment';
import NoMatch from './NoMatch';
import { Redirect } from "react-router-dom";
import DocumentMeta from 'react-document-meta';
import $ from 'jquery';
class EventsPage extends React.Component {

    constructor() {
        super();
        this.state = {
            events_data: '',
            success: false,
            eventTypeVal: "All",
            filterEventVal: 'All',
            eventLocationVal:'All locations',
            filterLocationVal:'All locations'
        };
    }

    changeHandler = e => {
        this.setState({ eventTypeVal: e.target.value });
    };
    LocationchangeHandler = e => {
        this.setState({ eventLocationVal: e.target.value });
    };

    filterEvents() {
        this.setState({ filterEventVal: this.state.eventTypeVal , filterLocationVal: this.state.eventLocationVal });
        console.log('filterEventVal: '+this.state.eventTypeVal+'  filterLocationVal: '+ this.state.eventLocationVal)
    }
    globalstylefun = () => {
        const globalstyle = window.adminStyle;
          if (globalstyle) {
            $('h2, .sectiontitle-headline').css({ "color": globalstyle.global_components_headline, "font-family": globalstyle.h2_fonts});
            $('h1').css({ "color": globalstyle.global_components_headline_h1, "font-family": globalstyle.h1_fonts });
            $('p').css({ "color": globalstyle.global_paragraph_text, "font-family": globalstyle.paragraph_fonts });
            $('.sub-title, .sectiontitle-eyebrow_text, .blog_card_category, .brand_heading').css("color", globalstyle.eyebrow_text_color);
            $('.btn-color').css("color", globalstyle.button_text_color);
            $('.btn-color').css({ "background-color": globalstyle.button_outlinebackground_color, "border": '1px solid' + globalstyle.button_outlinebackground_color });
            $('.cta_label_color').css({ "color": globalstyle.inline_linkscta, 'border-bottom': '2px solid' + globalstyle.inline_linkscta });
            $(".btn-color").mouseover(function () {
              $(this).css({ "background-color": globalstyle.button_hover, "border": '1px solid' + globalstyle.button_hover });
            }).mouseout(function () {
              $(this).css({ "background-color": globalstyle.button_outlinebackground_color, "border": '1px solid' + globalstyle.button_outlinebackground_color });
            });
          } 
      }
    componentDidMount() {
        axios.get(apiConfig().API_URL + '/events/')
            .then(response => {
                this.setState({
                    events_data: response.data.data,
                    success: response.data.success,
                    message: response.data.message
                }, () => {
                    if (this.state.success == true) {
                      window.adminStyle = this.state.events_data && this.state.events_data.global_style;
                      this.globalstylefun();
                    } }
                )
                console.log(response);
            });
            window.globalstylefun = this.globalstylefun;
            document.addEventListener("click", function(e) {
              window.globalstylefun();
            });
            window.CallTrk.swap();
    }
    componentDidUpdate(){
        window.CallTrk.swap();
      }
    render() {
        var success = this.state.success;
        var events_data = this.state.events_data;
        var location_city = events_data.all_locations;
        var message = this.state.message;
        var toShow;
        var month;
        var year;
        var weekday;
        console.log(events_data);

        if (success) {
            var upcomingEvents = events_data.upcoming_events;
            upcomingEvents = upcomingEvents.filter(data => {
                if (this.state.filterEventVal == "All" && this.state.filterLocationVal == "All locations") {
                    return data;
                } else if (this.state.filterEventVal === "All" && data.city == this.state.filterLocationVal) {
                    return data;
                } else if (data.event_type == this.state.filterEventVal && this.state.filterLocationVal == "All locations") {
                    return data;
                }else if (data.event_type == this.state.filterEventVal && data.city == this.state.filterLocationVal) {
                    return data;
                } 
                return null;
            });

            var pastEvents = events_data.past_events;
            pastEvents = pastEvents.filter(data => {
                if (this.state.filterEventVal == "All" && this.state.filterLocationVal == "All locations") {
                    return data;
                } else if (this.state.filterEventVal === "All" && data.city == this.state.filterLocationVal) {
                    return data;
                } else if (data.event_type == this.state.filterEventVal && this.state.filterLocationVal == "All locations") {
                    return data;
                }else if (data.event_type == this.state.filterEventVal && data.city == this.state.filterLocationVal) {
                    return data;
                }
                return null;
            });
        }

        console.log(upcomingEvents, pastEvents);

        var month;
        var prevMonth;
        var pastPrevMonth;
        var pastMonth;
        const meta = {
            title: events_data.page_title,
            description: events_data.page_desc,
            canonical: window.location.href
          };
        return (
            <>
            <DocumentMeta {...meta} />
            {
                success === true ? [
                    <div className="events-page">
                        <div className="container">
                            <div className="row events-sec">
                                <div className="header-sec">
                                    <h2>Events Calendar</h2>
                                    <div className="filter-sec">
                                        <select onChange={this.LocationchangeHandler}>
                                            <option value='All locations'>All locations</option>
                                            {location_city[0].map((items) => (
                                                (items != '' && items != null) ? (
                                                    <option value={items}>{items}</option>
                                                ):('')
                                            ))}
                                        </select>
                                        <select name="inquiry-filter" onChange={this.changeHandler}>
                                            <option value="All">Event Type</option>
                                            <option value="Future Homeowner Event">Future Homeowner Event</option>
                                            <option value="Resident Event">Resident Event</option>
                                        </select>
                                        <a href="javascript:void(0);" className="btn-color" onClick={() => this.filterEvents()}>View Events</a>
                                    </div>
                                </div>
                                <div className="tabing-sec">
                                    <Tabs>
                                        <TabList>
                                            <Tab>Upcoming Events</Tab>
                                            <Tab>Past Events</Tab>
                                        </TabList>

                                        <TabPanel>
                                            {upcomingEvents.length > 0 ? (
                                                <div>
                                                    <div className="events-list">
                                                        {upcomingEvents.map((items, key) => (
                                                            prevMonth = month,
                                                            month = items.startDate.monthFull,
                                                            <div>
                                                                {prevMonth !== month ? (
                                                                <div className="events-list-month">
                                                                    <h3>{month} {items.startDate.year}</h3>
                                                                </div>
                                                                ):('')}
                                                                <div className="event">
                                                                    <div className="ev-date">
                                                                        <span>{moment(items.startDate.year + '-' + items.startDate.monthNum + '-' + items.startDate.day + ' 00:00:00','YYYY-M-D HH:mm:ss').format('dddd')}</span>
                                                                        <span>{items.startDate.day}</span>
                                                                    </div>
                                                                    <div className="ev-info">
                                                                        <div className="ev-details">
                                                                            {items.event_metas.categories[0] ? (
                                                                                <span>{items.event_metas.categories[0].post_title}</span>
                                                                            ):('')}
                                                                            <h2>{items.post_title}</h2>
                                                                            {items.post_content ? (
                                                                                toShow = items.post_content.substring(0, 150) + "...",
                                                                                <p dangerouslySetInnerHTML={{ __html: toShow.replace(/(?:\r\n|\r|\n)/g, '') }}></p>
                                                                            ) : ('')}
                                                                            <a href={'/event/' + items.post_name} title="VIEW DETAILS">VIEW DETAILS</a>
                                                                            {items.event_metas.event_address_time ? (
                                                                            <div className="event-time-venue">
                                                                            {items.event_metas.event_address_time[0].event_hours ? (
                                                                                <span>{items.event_metas.event_address_time[0].event_hours}</span>
                                                                            ):('')}
                                                                            {items.event_metas.event_address_time[0].physical_address_heading || items.event_metas.event_address_time[0].city || items.event_metas.event_address_time[0].state_abbreviation || items.event_metas.event_address_time[0].zip_code ? (
                                                                            <span>{items.event_metas.event_address_time[0].physical_address_heading} {items.event_metas.event_address_time[0].city}
                                                                            {items.event_metas.event_address_time[0].state_abbreviation || items.event_metas.event_address_time[0].zip_code ? (', '):('')}
                                                                            {items.event_metas.event_address_time[0].state_abbreviation} {items.event_metas.event_address_time[0].zip_code}</span>
                                                                            ):('')}
                                                                            </div>
                                                                            ):('')}
                                                                        </div>
                                                                        <div className="ev-img">
                                                                        {items.event_metas.event_gallery[0] ? (
                                                                            items.event_metas.event_gallery[0].image ? (
                                                                            <img src={items.event_metas.event_gallery[0].image.url} title={items.event_metas.event_gallery[0].image.title} alt={items.event_metas.event_gallery[0].image.title} />
                                                                            ):(<img src={require('../../../assets/images/TPH-default-image2.gif').default} title='event image' alt='event image' />)
                                                                        ):('')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="no-event">
                                                    <h2>No Events</h2>
                                                </div>
                                            ) }
                                        </TabPanel>
                                        <TabPanel>
                                            {pastEvents.length > 0 ? (
                                                <div>
                                                    
                                                    <div className="events-list">
                                                        {pastEvents.map((items, key) => (
                                                            pastPrevMonth = pastMonth,
                                                            pastMonth = items.startDate.monthFull,
                                                            <div>
                                                                {pastPrevMonth !== pastMonth ? (
                                                                    <div className="events-list-month">
                                                                        <h3>{pastMonth} {items.startDate.year}</h3>
                                                                    </div>
                                                                ):('')}
                                                                <div className="event">
                                                                    <div className="ev-date">
                                                                        <span>{moment(items.startDate.year + '-' + items.startDate.monthNum + '-' + items.startDate.day + ' 00:00:00','YYYY-M-D HH:mm:ss').format('dddd')}</span>
                                                                        <span>{items.startDate.day}</span>
                                                                    </div>
                                                                    <div className="ev-info">
                                                                        <div className="ev-details">
                                                                            {items.event_metas.categories[0] ? (
                                                                                <span>{items.event_metas.categories[0].post_title}</span>
                                                                            ):('')}
                                                                            
                                                                            <h2>{items.post_title}</h2>
                                                                            {items.post_content ? (
                                                                                toShow = items.post_content.substring(0, 150) + "...",
                                                                                <p dangerouslySetInnerHTML={{ __html: toShow.replace(/(?:\r\n|\r|\n)/g, '') }}></p>
                                                                            ) : ('')}
                                                                            <a href={'/event/' + items.post_name} title="VIEW DETAILS">VIEW DETAILS</a>
                                                                            {items.event_metas.event_address_time ? (
                                                                            <div className="event-time-venue">
                                                                                {items.event_metas.event_address_time[0].event_hours ? (
                                                                                    <span>{items.event_metas.event_address_time[0].event_hours}</span>
                                                                                ):('')}
                                                                                {items.event_metas.event_address_time[0].physical_address_heading || items.event_metas.event_address_time[0].city || items.event_metas.event_address_time[0].state_abbreviation || items.event_metas.event_address_time[0].zip_code ? (
                                                                                <span>{items.event_metas.event_address_time[0].physical_address_heading} {items.event_metas.event_address_time[0].city}
                                                                                {items.event_metas.event_address_time[0].state_abbreviation || items.event_metas.event_address_time[0].zip_code ? (', '):('')}
                                                                                {items.event_metas.event_address_time[0].state_abbreviation} {items.event_metas.event_address_time[0].zip_code}</span>
                                                                                ):('')}
                                                                               </div>
                                                                            ):('')}
                                                                        </div>
                                                                        <div className="ev-img">
                                                                            {items.event_metas.event_gallery[0] ? (
                                                                                items.event_metas.event_gallery[0].image ? (
                                                                                <img src={items.event_metas.event_gallery[0].image.url} title={items.event_metas.event_gallery[0].image.title} alt={items.event_metas.event_gallery[0].image.title} />
                                                                                ):( <img src={require('../../../assets/images/TPH-default-image2.gif').default} title='event image' alt='event image' />)
                                                                            ):('')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="no-event">
                                                    <h2>No Events</h2>
                                                </div>
                                            ) }
                                        </TabPanel>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                ] : [message != 'Not Completed' ?  <TPHLoader/>  :  <Redirect to={<NoMatch/>} /> ]
            }
            </>
        )
    }
}

export default EventsPage;
