import React from 'react';
import HeadingSection from './HeadingSection';
class PromotionBlock extends React.Component {  

  render() {    
    var promo_data = this.props.promo_data ;

     return (  
          promo_data.promo_label && promo_data.promo_link && promo_data.promo_headline ? [
            <div className="spring-section" style={{backgroundColor: promo_data.promo_bg_color}}>  
                <HeadingSection subtitle={promo_data.promo_sub_text} title={promo_data.promo_headline} />
                {promo_data.promo_description ?( <p className="title-info" dangerouslySetInnerHTML={{__html:promo_data.promo_description.replace(/(?:\r\n|\r|\n)/g, '')}}></p> ):('')}
                {promo_data.promo_label && promo_data.promo_link ? (
                      <a href={promo_data.promo_link} title={promo_data.promo_label} className="learn-more btn-color" >{promo_data.promo_label}</a>
                ):('')}
            </div>  
          ]:['']
     );  
  }  
}  

export default PromotionBlock; 