import React from "react";
import "./TPHLoader.scss";

class TPHLoader extends React.Component {
  render() {
   
    return (
      <div id="loader">
        {/* <div id="loader-icon"><img src={require('../../assets/images/loader1.svg')} /></div> */}
        <div>
          <h2>one moment</h2>
        </div>
        <div className="arrows-loading">
          <div>
            <img src={require("../../assets/images/lemongreen.png").default}></img>
          </div>
          <div>
            <img src={require("../../assets/images/greendark.png").default}></img>
          </div>
          <div>
            <img src={require("../../assets/images/darkgray.png").default}></img>
          </div>
          <div>
            <img src={require("../../assets/images/gray.png").default}></img>
          </div>
          <div>
            <img src={require("../../assets/images/lemongreen.png").default}></img>
          </div>
          <div>
            <img src={require("../../assets/images/yellowgreen.png").default}></img>
          </div>
          <div>
            <img src={require("../../assets/images/lightgray.png").default}></img>
          </div>
          <div>
            <img src={require("../../assets/images/lightgray.png").default}></img>
          </div>
          <div>
            <img src={require("../../assets/images/gray.png").default}></img>
          </div>
          <div>
            <img src={require("../../assets/images/lemongreen.png").default}></img>
          </div>
          <div>
            <img src={require("../../assets/images/gray.png").default}></img>
          </div>
          <div>
            <img src={require("../../assets/images/yellowgreen.png").default}></img>
          </div>
        </div>
      </div>
    );
  }
}

export default TPHLoader;
