
import React from 'react'
import fancybox from '@fancyapps/fancybox' // eslint-disable-line no-unused-vars
import './ScheduleTourForm.scss';
import $ from 'jquery';
import cookie from 'react-cookies';
import logo from '../../assets/images/logo.png';

class QmiReserveForm extends React.Component { 
    TourloadMarketoForm() {

        var that = this;
        const zillow_hour = that.props.form_details.zillow_hour;
        const title = that.props.form_details.qmi_title;
        const agent_name = that.props.agent_name;
        const division_name = this.props.division_name;
        const map_directions_link = that.props.form_details.map_directions_link;
        const community_id = that.props.form_details.community_id;
        const crm_salesforce_id = that.props.form_details.crm_salesforce_id;
        // const post_type = that.props.post.post_type;
        const ID = that.props.form_details.ID;
		const form_headline = that.props.form_details.form_headline;
		const form_text = that.props.form_details.form_text;
		const new_row = that.props.form_details.new_row;
		const contact_heading = that.props.form_details.contact_heading;
		const contact_text = that.props.form_details.contact_text;
		const appointment_heading = that.props.form_details.appointment_heading;
		const appointment_text = that.props.form_details.appointment_text;
		const appointment_disclaimer = that.props.form_details.appointment_disclaimer;
		const agent_heading = that.props.form_details.agent_heading;
		const agent_text = that.props.form_details.agent_text;
		const agent_info = that.props.form_details.agent_info;
		const section_1_text = that.props.form_details.section_1_text;
		const section_2_text = that.props.form_details.section_2_text;
		const button_text = that.props.form_details.button_text;
        const form_aba_link = that.props.form_details.form_aba_link;
        const cookie_value = this.state.mkto_cookie;
        var agent_detail = '';
        const homesiteNumber = that.props.homesiteNumber;        
        
        if (window.MktoForms2 !== "undefined") {
            window.MktoForms2.loadForm("//app-sj10.marketo.com", "167-BTV-942", 4738, function(form) {
                window.JQUERY('.headtxt').html(title);
                window.JQUERY('#Step1Row1').html(form_headline);
                window.JQUERY('#Step1Row2').html(form_text);
                window.JQUERY('#stepsInfo').append(new_row);
                window.JQUERY('#Step2Row1').html(contact_heading);
                window.JQUERY('#Step2Row2').html(contact_text);
                window.JQUERY('#Step3Row1').html(appointment_heading);
                window.JQUERY('#Step3Row2').html(appointment_text);
                window.JQUERY('#Step3-Dis').html(appointment_disclaimer);
                window.JQUERY('#Step5Row1').html(agent_heading);
                window.JQUERY('#Step5Row2').html(agent_text);
                window.JQUERY('#Step5Row3 img').attr({src:require('../../assets/images/last-icon.png').default});
                window.JQUERY('#Step4Row2').html(section_1_text);
                window.JQUERY('#Step4Row4').html(section_2_text);
                window.JQUERY('#Step4Row5 a').html(button_text);
                window.JQUERY('#Step4Row5 a').attr('href',form_aba_link);
                if(agent_name.agents_contact_name || agent_name.agents_contact_phone || (button_text &&form_aba_link)){
                    agent_detail = '<p>'+agent_info+'<span class="form_agent_name" style="display:block;margin:10px 0 5px;">'+agent_name.agents_contact_name+'</span><span class="form_agent_number" style="display:block;margin-bottom:5px;">New Home Specialists</span><span class="form_agent_number" style="display:block;margin-bottom:10px;">'+agent_name.agents_contact_phone+'</span>';
                    if(button_text!='' && form_aba_link!=''){
                        agent_detail = agent_detail+'<a class="Prequalified_button" href='+form_aba_link+' target="_blank" style="padding:10px;display:inline-block;color:#545454;border-radius:0;font-weight:600;background-color:#6b8444;text-decoration:none;letter-spacing: 3px;">'+button_text+'</a></p>';
                    }
                }

                window.JQUERY('#Step5Row4').html(agent_detail);

                if(button_text=='' && form_aba_link=='') {
                    window.JQUERY("#Step4Row5").css("display", "none");
                }
                window.JQUERY('#tour_form .logo > a:first-child').attr({href: window.location.origin ,title:"Peacn Ridge", alt:"Peacn Ridge"});
                window.JQUERY('#tour_form .logo img').attr({src:logo,title:"Peacn Ridge", alt:"Peacn Ridge"});
                window.JQUERY('#tour_form #LblmktoRadio_88468_0 .appointment img').attr({ title: "On-Site Appointment"});
                window.JQUERY('#tour_form #LblmktoRadio_88468_1 .appointment img').attr({ title: "Virtual Appointment"});
               
           
                if(map_directions_link !== ""){
                    window.JQUERY('#DriveDir a').attr("target","_blank");
                    window.JQUERY('#DriveDir a').attr("href",map_directions_link);
                }else{
                    window.JQUERY('#DriveDir').hide();
                }
                
                window.JQUERY('#mktoForm_4738 a#PrivacyLink').attr("href",window.location.origin+"/privacy-policy/");
                window.JQUERY('#mktoForm_4738 input[name="External_Lead_Id__c"]').val(community_id);
                
                setTimeout(function(){ window.JQUERY( "#mktoForm_4738 #requestedDate" ).trigger( "change" ); }, 10000);
                window.JQUERY("#requestedDate").change(function(){
                    window.JQUERY("#apptRequestedTime option").each(function() {
                        window.JQUERY(this).remove();
                    });
                    var addHour = ["10:00 am", "11:00 am" , "12:00 pm" , "01:00 pm", "02:00 pm", "03:00 pm", "04:00 pm", "05:00 pm"];
                    window.JQUERY.each(addHour, function(index, value){
                        window.JQUERY("#apptRequestedTime").append( window.JQUERY("<option>",{
                              value: value,
                              text: value
                        }));
                    });
                    var requestedDate =  window.JQUERY('#requestedDate').val();
                    if(requestedDate !=''){
                        window.JQUERY.ajax({
                            type:"POST",
                            url: "/wp-admin/admin-ajax.php",
                            data:{
                                action:"get_shedule_hour",
                                requestedDate: requestedDate,
                                n_id : ID,
                                result : zillow_hour
                            },
                            success:function(response){
                                response = JSON.parse(response);
                                if(response.close_day == 'true'){
                                    if( window.JQUERY('#mktoForm_4738 .Row_21:has(p)')){
                                        window.JQUERY('#mktoForm_4738 .Row_21 p').remove();
                                    }
                                    window.JQUERY('#mktoForm_4738 .Row_21').append('<p class="red-error">Appointments are not available for this date.</p>');
                                    window.JQUERY('#mktoForm_4738 .Row_23 a').css('pointer-events','none');
                                }else{
                                    window.JQUERY('#mktoForm_4738 .Row_23 a').css('pointer-events','unset');
                                    window.JQUERY('#mktoForm_4738 .Row_21 p').remove();
                                }
                                
                                var startTime = 0;
                                var option_length =  window.JQUERY('#apptRequestedTime > option').length;
                                window.JQUERY("#apptRequestedTime option").each(function() {
                                    if( window.JQUERY(this).val() !=response.start_time){
                                        window.JQUERY(this).remove();
                                        startTime++;
                                        if(startTime==option_length){
                                            window.JQUERY.each(addHour, function(index, value){
                                                window.JQUERY("#apptRequestedTime").append( window.JQUERY("<option>",{
                                                    value: value,
                                                    text: value
                                                }));
                                            });
                                            window.JQUERY("#apptRequestedTime").val('10:00 am');
                                        }
                                    }else{								
                                        window.JQUERY("#apptRequestedTime").val(response.start_time);
                                        return false;
                                    }
                                });
                                var endtime = false;var k =1;
                                window.JQUERY("#apptRequestedTime option").each(function() {
                                    if( window.JQUERY(this).val() ==response.end_time || endtime==true){
                                        endtime = true;
                                        k++;
                                        if(k!=2){ window.JQUERY(this).remove();}
                                    }
                                });
                            }
                        });
                    }
                });
              
            });
            
            window.MktoForms2.whenReady(function (form) {

                window.JQUERY("#mktoForm_4738").find('[name="Communities_of_Interest__c"]').val(crm_salesforce_id);
                window.JQUERY("#mktoForm_4738").find('[name="formURL"]').val(window.location.href);
                window.JQUERY("#mktoForm_4738").find('[name="Division__c"]').val(division_name);
                window.JQUERY("#mktoForm_4738").find('[name="communityName"]').val(title);
                window.JQUERY("#mktoForm_4738").find('[name="Community_Home_Site_Floor_Plan_Pref_1__c"]').val(homesiteNumber);
                
                var formfields = form.vals();
                var formfields_names = Object.keys(formfields);

                var formfilterValue = cookie_value;
                console.log('Cookie value: '+formfilterValue);
                var outputArray = [];
                for (var i = 0; i < formfields_names.length; i++){
                    if(formfields_names[i] && formfields_names[i]!='munchkinId' && formfields_names[i]!='formid'){
                        if ((window.JQUERY.inArray(formfields_names[i], outputArray)) == -1){
                            outputArray.push(formfields_names[i]);
                        }
                    }                               
                }
                const reducedArray = outputArray.reduce((fields_names, feilds) => `${fields_names}${feilds},` ,'')
                var data = "";
                var data = {
                    action: 'formnames',
                    requestData: reducedArray,
                    filterType:'cookies',
                    filterValue: formfilterValue
                };
                window.JQUERY.ajax({
                    type: 'POST',
                    data: data,
                    url: "/wp-admin/admin-ajax.php",
                    success:function(response){
                        var field_havevalues = [];
                        var results = [];
                        results = JSON.parse(response);
                        //var dissatisfaction = results.dissatisfaction__c;
                       // var value_here = Array.from(dissatisfaction.split(';'));
                        var finalvaluestofill = {};
                        window.JQUERY.each(formfields_names,function(value, elem){
                            if(elem){
                                var keys = elem.toLowerCase();
                                if(keys in results){
                                    if(results[keys] && results[keys] != ''){
                                        finalvaluestofill[elem] = results[keys];
                                        field_havevalues.push(elem);
                                    }
                                }
                            }
                        });

                        if (finalvaluestofill) {
                            form.vals(finalvaluestofill);
                        }
                    }
                });
            });
        }

    }

    componentDidMount() {
        this.state = {
            mkto_cookie: cookie.load('_mkto_trk')
        };
        this.TourloadMarketoForm();
 
    }
    render() {
        var pattern_image = this.props.form_details.pattern_image;
        var thumbnail_image = this.props.form_details.tour_post_thumbnail;
        var tour_post_title = this.props.form_details.title;
        return (
            
        <div id="tour_form">
            <div class="tourpop_form" >
                <div class="form-column">
                    <form id="mktoForm_4738"></form>		
                </div>	
                <div className="form-image">
                    {pattern_image ? (
                        <div className="two_image">
                            <img src={pattern_image.url} alt={tour_post_title} title={tour_post_title}/>	
                        </div>
                    ):('')}  
                    <picture><img src={thumbnail_image} alt={tour_post_title} title={tour_post_title}/></picture>
                </div>		
            </div>
        </div>
        )
    }
}

export default QmiReserveForm