import React from 'react';
import './LivingSmartTabbed.scss';
import HeadingSection from './HeadingSection';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {Accordion,Card,Button} from 'react-bootstrap';
import 'react-tabs/style/react-tabs.css';
import $ from 'jquery';


class LivingSmartTabbed extends React.Component {  
    componentDidMount() {

        $(".living-smart-tabbed .accordion .card ").on('click',function() {
            $(this).siblings().removeClass("active");
            $(this).toggleClass("active");   
        })

    }

  render() {   
    var living_smart_tabbed = this.props.living_smart_tabbed ;    
    var target_change;
    if(living_smart_tabbed.open_in_new_tab==true){
target_change="_blank";
    }
    else{
        target_change="";
    }
    var window_width = window.screen.width;  
     return (  
      (living_smart_tabbed.tabbed_section.length > 0)? (
          <div className="blog-section living-smart-tabbed">  
                  <div className="blog-content">
                        <div className="header-section">
                            <div className="heading-section-left">
                                <HeadingSection 
                                subtitle={living_smart_tabbed.sub_text} 
                                title_part_1={living_smart_tabbed.headline}    
                                title_part_2={living_smart_tabbed.headline_part_2}  
                                headline_part_1_color = {living_smart_tabbed.headline_part_1_color}  
                                headline_part_2_color = {living_smart_tabbed.headline_part_2_color}  
                                />
                            </div>
                            <div className="heading-section-right">
                            {living_smart_tabbed.description ? (
                                <p className="title-info" dangerouslySetInnerHTML={{__html:living_smart_tabbed.description.replace(/(?:\r\n|\r|\n)/g, '')}}></p>
                            ):('')}
                            {living_smart_tabbed.cta_label && living_smart_tabbed.cta_link ? (
                                <a href={living_smart_tabbed.cta_link}  title={living_smart_tabbed.cta_label} className="learn-more cta_label_color" target={target_change}>{living_smart_tabbed.cta_label}</a>
                            ):('')}
                            </div>
                        </div>
                        {living_smart_tabbed.tabbed_section.length > 0 ? [
                            <div className="living-smart-div">
                            {(window_width >= 1025) ? (  
                                <Tabs>
                                    <TabList className="living-smart-tablist">
                                    {living_smart_tabbed.tabbed_section.map((items,key) => [
                                        items.headline ? (
                                            <Tab className="living-smart-tab divide-equal">
                                                {items.tab_icon ? (
                                                    <img src={items.tab_icon.url} title={items.tab_icon.title} alt={items.tab_icon.title} className='tab-icon' />
                                                ):('')}
                                                <div className='tab-heading'>{items.headline}</div>
                                            </Tab>
                                        ):('')
                                    ])}
                                    </TabList>
                                    {living_smart_tabbed.tabbed_section.map((items,key) => [
                                    <TabPanel>
                                        {items.image ? (
                                            <div className="tabbed-img divide-equal">
                                                <img src={items.image.url} title={items.image.title} alt={items.image.title} />
                                            </div>
                                        ):('')}
                                        {items.description ? (
                                            <div className="tabbed-text divide-equal" dangerouslySetInnerHTML={{__html:items.description.replace(/(?:\r\n|\r|\n)/g, '')}}></div>
                                        ):('')} 
                                    </TabPanel>
                                    ])}
                                </Tabs>
                            ):(
                                <Accordion defaultActiveKey="1">
                                    {living_smart_tabbed.tabbed_section.map((items,key) => [
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey={key+1}>
                                            {items.tab_icon ? (
                                                <img src={items.tab_icon.url} title={items.tab_icon.title} alt={items.tab_icon.title} className='tab-icon' />
                                            ):('')}
                                            <div className='tab-heading'>{items.headline}</div>
                                            <div className="arrowRight"></div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={key+1}>
                                            <Card.Body> 
                                                {items.image ? (    
                                                    <div className="tabbed-img divide-equal">
                                                        <img src={items.image.url} title={items.image.title} alt={items.image.title} />
                                                    </div>
                                                ):('')}
                                                {items.description ? (
                                                    <div className="tabbed-text divide-equal" dangerouslySetInnerHTML={{__html:items.description.replace(/(?:\r\n|\r|\n)/g, '')}}></div>
                                                ):('')} 
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    ])}
                                </Accordion>
                            )}
                            </div>
                        ]:['']}
                  </div>
          </div> 
        ):('') 
     );  
  }  
}  

export default LivingSmartTabbed; 