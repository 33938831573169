import React from 'react';
import './Residence.scss';
import Carousel from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withAuth0 } from "@auth0/auth0-react";
import $ from 'jquery';

class ResidenceScreen3 extends React.Component {
    constructor() {
        super()

        this.state = {
            nav1: null,
            nav2: null,
            nav3: null,
            token: '',
            dreamweaver_load: false 
        }
        this.bdx_ifp_plugin_load = this.bdx_ifp_plugin_load.bind(this);
        this.bdx_isp_plugin_load = this.bdx_isp_plugin_load.bind(this);
    }

    getUserMetadata = async () => {   
        const accessToken = await this.props.auth0.getAccessTokenSilently();
        this.setState({ 
            token: accessToken != '' ? accessToken : '',
            dreamweaver_load: true 
        });
    };

    bdx_ifp_plugin_load(){
        var that = this;
        var payload = { 
        planNumber: that.props.residence_component.floorplans.bdx_plan_id, 
        container: "iframe-div",
        accessToken: this.state.token,
        authDomain: process.env.Auth0_Domain,
        };

     window.MEDIALOADER.initFloorPlan(payload, function (data) {
        console.log("callback:", data);
    });
    console.log("IFP Loaded");
    this.setState({ 
        dreamweaver_load: false, 
    });
    }
    bdx_isp_plugin_load(){
        var that = this;
        var payload = {
            communityNumber: that.props.residence_component.common_detail.e1_community_id,
            container: "iframe-div",
            allowListings: true,
            allowPopup: true,
            accessToken: this.state.token,
            authDomain: process.env.Auth0_Domain,
            };
    
         window.MEDIALOADER.initSitePlan(payload, function (data) {
            console.log("callback:", data);
        });
        console.log("ISP Loaded");
        this.setState({ 
            dreamweaver_load: false, 
        });
    }
    componentDidMount() {

        if(this.props.auth0.isAuthenticated == true){
            this.getUserMetadata();
        }
        else{
            this.setState({ 
                dreamweaver_load: true, 
            });
        }

      this.setState({
          nav1: this.slider1,
          nav2: this.slider2,
          nav3: this.slider3
      });
      if ($('.residence-details').attr("id") == "screen-3") {
        $('.plan-features').addClass('screen-3');
      }
      $('.residence-header').removeClass('bdx-added');

      if($('.residence-image').hasClass('bdx_parent_div')){
          $('.residence-header').addClass('bdx-added');
      }
    }

    componentDidUpdate(){
        if(this.state.dreamweaver_load == true){
            if(this.props.residence_component.floorplans.show_floorplan_bdx == true){
                if(this.props.residence_component.floorplans.bdx_type == 'isp_plugin'){
                    this.bdx_isp_plugin_load();
                }
                else if( this.props.residence_component.floorplans.bdx_type == 'ifp_plugin' && this.props.residence_component.floorplans.bdx_plan_id != '' ){
                    this.bdx_ifp_plugin_load();
                }
            } 
        }
    }
    
    render() {
        const slider1Settings = {
            asNavFor: this.state.nav2,
            ref: slider => (this.slider1 = slider),
            dots: true,
            infinite:false
        }
        const slider2Settings = {
            asNavFor: this.state.nav3,
            ref: slider => (this.slider2 = slider),
            arrows: false,
            dots: false,
            infinite:false,
            draggable: false,
            focusOnSelect: true
        }
        const slider3Settings = {
            asNavFor: this.state.nav1,
            ref: slider => (this.slider3 = slider),
            arrows: false,
            dots: false,
            infinite:false,
            draggable: false,
            fade: true,
            speed: 600
        }
        var residence_component = this.props.residence_component.floorplans;

        var bdx_class;
        var show_bdx_ifp;
        
        if(residence_component.show_floorplan_bdx == true && residence_component.bdx_type != ''){
            if(residence_component.bdx_type == 'ifp_plugin' && residence_component.bdx_plan_id == ''){
                bdx_class = '';
                show_bdx_ifp = false;
            }
            else{
                bdx_class = 'bdx_parent_div';
                show_bdx_ifp = true;
            }
        }
        else{
            bdx_class = '';
            show_bdx_ifp = false;
        }

        return (
            <div className="residence-details" id="screen-3">
            <div className={"residence-image "+bdx_class}> 
            {residence_component.floorplan_images || show_bdx_ifp ? [
                show_bdx_ifp ? [
                    <div className="bdx_plugin_div iframe-div" id="iframe-div"></div>
                ]:[
                    <div className="iframe-div">
                        <iframe src={residence_component.floorplan_images} title="Plan iframe"></iframe>
                    </div>
                ]
            ]:['']}
            </div>
            {/* <div className="residence-content">
                <div className="plan-name">Floorplan</div>
                {residence_component.floorplans_subtext ? (
                    <div className="plan-description">
                    <p>{residence_component.floorplans_subtext }</p>
                </div>
                ):('')}
            
                {residence_component.floorplan_images.length > 0 ? [
                <div className="plan-btns">
                    <div className="thumbnail-slider-wrap">
                        <Carousel  {...slider2Settings} >
                            {residence_component.floorplan_images.map(items => ( 
                                <div className="residence-btns">
                                    <p className="plan-btn" title={items.image_title}>{items.image_title}</p>
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>,
                <div className="btn-description">
                    <Carousel  {...slider3Settings} >
                        {residence_component.floorplan_images.map(items => (
                        <div>
                            <div className="btn-name">{items.image_title}</div>
                            <p dangerouslySetInnerHTML={{__html:items.image_caption.replace(/(?:\r\n|\r|\n)/g, '')}}></p>
                        </div>
                        ))}
                    </Carousel>
                </div>
                ]:[]}
            </div> */}
        </div>
        );
    }
}
export default withAuth0(ResidenceScreen3); 
